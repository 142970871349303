<template>
  <div id="collapse-B" class="collapse show">
    <div ref="history-container" class="card-body position-relative">
      <div class="row">
        <history-filter
          v-model:text-filter="textFilter"
          v-model:date-from="dateFrom"
          v-model:date-to="dateTo"
          v-model:shared-only="sharedOnly"
          v-model:type-filter="typeFilter"
          @updated="refresh"
        />
        <history-entries
          v-model:pgNum="pgNum"
          :entries="entries"
          :total-pages="totalPages"
          @page-updated="refresh"
          @entry-updated="updateEntry"
          @delete-entry="deleteEntry"
          @burn-trauma="burnTrauma"
        />
      </div>
    </div>
  </div>
  <burn-animation />
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import HistoryFilter from './HistoryFilter.vue';
import HistoryEntries from './HistoryEntries.vue';
import BurnAnimation from '../journal-today/BurnAnimation.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Modal } from 'bootstrap';

export default {
  components: {
    HistoryFilter,
    HistoryEntries,
    BurnAnimation,
  },
  setup() {
    const journalApi = inject('api').journalApi();
    const loading = inject('$loading');
    dayjs.extend(utc);

    onMounted(() => {
      refresh();
    });

    const entries = ref([]);
    const historyContainer = ref(null);
    const pgNum = ref(1);
    const pgSz = ref(5);
    const dateFrom = ref('');
    const dateTo = ref('');
    const sharedOnly = ref(false);
    const textFilter = ref('');
    const typeFilter = ref('');
    const totalPages = ref(0);

    const refresh = async () => {
      const loader = loading.show({
        container: historyContainer.value,
        isFullPage: false,
      });
      const response = await journalApi.getJournalHistory(
        pgNum.value - 1,
        pgSz.value,
        dateFrom.value,
        dateTo.value,
        sharedOnly.value,
        textFilter.value,
        typeFilter.value
      );
      entries.value = response.data.data;
      totalPages.value = response.data.totalPages;
      loader.hide();
    };

    const updateEntry = async (updatedEntryData) => {
      const entry = entries.value.find(
        (item) => item.id == updatedEntryData.entryId
      );
      entry.isShared = updatedEntryData.isShared;
      await journalApi.updateEntry(updatedEntryData.journalId, entry);
      refresh();
    };

    const burnTrauma = async (entry) => {
      const burnModal = new Modal(document.getElementById('burnModal'), {
        keyboard: false,
      });
      burnModal.show();
      const response = await journalApi.burnTrauma(entry.journalId, entry);
      // disable burning animation after 5000ms or when the response has been received from backend, whichever takes longer
      setTimeout(() => {
        if (response.data.entries) {
          burnModal.hide();
          refresh();
        }
      }, 5000);
    };

    const deleteEntry = async (entry) => {
      const loader = loading.show({
        container: historyContainer.value,
        isFullPage: false,
      });
      await journalApi.deleteEntry(entry.journalId, entry);
      loader.hide();
      refresh();
    };

    return {
      entries,
      'history-container': historyContainer,
      pgNum,
      dateFrom,
      dateTo,
      sharedOnly,
      textFilter,
      typeFilter,
      refresh,
      updateEntry,
      burnTrauma,
      deleteEntry,
      totalPages,
    };
  },
};
</script>
