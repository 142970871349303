<template>
  <div class="container">
    <div class="row">
      <div class="col mt-4 mb-3">
        <h1 v-if="localCurrContract.id == 0">Start a Personal Contract
        </h1>
      </div>
    </div>
  </div>
  <!-- Start Contract -->
  <form class="container-md">
    <div :class="!localCurrContract.isSigned
      ? ['bg-light', 'shadow', 'pb-4', 'mb-4', 'row']
      : 'row'
      ">
      <div ref="contract-container" class="col">
        <div class="row">
          <!-- Title -->
          <div class="col">
            <div v-if="editTitleMode || localCurrContract.id == 0" class="form-floating mb-3">
              <input id="title" ref="title-input" v-model="localCurrContract.title" type="text" class="form-control"
                placeholder="Contract Title" @input="makeDirty" @blur="editTitleMode = false" />
            </div>

            <!-- Contract -->
            <label v-if="!editTitleMode && localCurrContract.id != 0" class="form-label h3 text-muted">{{
              localCurrContract.title
            }}<button id="edit-title-btn" class="btn btn-sm btn-link" @click="editTitle">
                <i class="fas fa-edit"></i></button></label>
            <div id="contract-editor-div">
              <contract-textarea v-model:currContract="localCurrContract" :is-signed="localCurrContract.isSigned"
                container-id="contract-editor-div" height="50vh" @dirty="makeDirty"></contract-textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- Appendix -->
      <div v-if="localCurrContract.isSigned" class="bg-light shadow pb-4 mb-4 mt-5">
        <div class="row">
          <div class="col">
            <label for="appendixTextArea" class="form-label text-muted h4">Appendix</label>
            <div id="appendix-editor-div">
              <contract-textarea v-model:currContract="localCurrContract" contract-field="appendix" :is-signed="false"
                container-id="appendix-editor-div" height="25vh" @dirty="makeDirty"></contract-textarea>
            </div>
            <!-- Buttons -->
            <div class="d-flex justify-content-around mt-3">
              <button id="close-contract-btn" type="button" class="btn btn-secondary" @click="cancel">
                Close
              </button>
              <button
                id="save-contract-btn"
                :disabled="
                  !localCurrContract.title || !localCurrContract.isDirty
                "
                type="button"
                class="btn btn-primary"
                @click="finalizeContract"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="localCurrContract.id == 0" class="d-flex justify-content-around mt-3">
        <button id="close-contract-btn" type="button" class="btn btn-secondary" @click="cancel">
          Cancel
        </button>
        <button
          id="save-contract-btn"
          :disabled="
            !localCurrContract.title ||
            localCurrContract.contract == '<p><br></p>' ||
            !localCurrContract.isDirty
          "
          type="button"
          class="btn btn-primary"
          @click="saveContract"
        >
          Create Contract
        </button>
      </div>
      <div v-else class="d-flex justify-content-center justify-content-sm-around mt-3">
        <button id="close-contract-btn" type="button" class="btn btn-secondary" @click="cancel">
          Close
        </button>
        <button v-if="!localCurrContract.isSigned" id="sign-contract-btn" type="button" class="btn btn-primary" :disabled="localCurrContract.contract == '' ||
          localCurrContract.contract == '<p><br></p>' ||
          !localCurrContract.title
          " @click="toggleSigModal">
          <i class="fas fa-signature pe-2"></i>
          Sign Contract
        </button>
        <button
          id="save-contract-btn"
          :disabled="!localCurrContract.title || !localCurrContract.isDirty"
          type="button"
          class="btn btn-success"
          @click="finalizeContract"
        >
          Save
        </button>
      </div>
      <contract-signing ref="contract-signing-comp" :full-name="fullName" @signed="signContract"></contract-signing>
    </div>
  </form>
</template>

<script>
import { computed, ref, inject, nextTick } from 'vue';
import { useStore } from 'vuex';
import ContractTextarea from './ContractTextarea.vue';
import ContractSigning from './ContractSigning.vue';
import { makeToast } from "../../../../../services/lib/toastLib"

export default {
  components: {
    ContractTextarea,
    ContractSigning,
  },
  props: {
    viewContract: {
      type: Boolean,
      required: true,
    },
    currContract: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'update:viewContract',
    'save',
    'update:currContract',
    'update:isDirty',
  ],
  setup(props, context) {
    const loading = inject('$loading');
    const cancel = () => {
      if (localCurrContract.value.isDirty) {
        if (
          !window.confirm(
            'Are you sure you want to proceed? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      localCurrContract.value.isDirty = false;
      context.emit('update:viewContract', false);
    };

    const saveContract = () => {
      context.emit('save');
      localCurrContract.value.isDirty = false;
      makeToast({ message: 'Contract saved!', type: 'success' })
    };
    const finalizeContract = () => {
      context.emit('save');
      localCurrContract.value.isDirty = false;
      makeToast({ message: 'Contract saved!', type: 'success' })
      context.emit('update:viewContract', false);
    };
    const localCurrContract = computed({
      get: () => props.currContract,
      set: (value) => context.emit('update:currContract', value),
    });

    const makeDirty = () => {
      localCurrContract.value.isDirty = true;
    };

    const contractSigningComp = ref(null);
    const toggleSigModal = () => {
      contractSigningComp.value.toggleSigModal();
    };

    // signature rendering
    const store = useStore();
    const fullName = computed(() => {
      return store.getters.fullName;
    });

    const contractContainer = ref(null);
    const signContract = (fontClass) => {
      toggleSigModal();
      let loader = loading.show({
        container: contractContainer.value,
        isFullPage: false,
      });
      localCurrContract.value.contract += `\n\n<h1><span class="${fontClass}">${fullName.value}</span></h1>`;
      localCurrContract.value.isSigned = true;
      saveContract();
      loader.hide();
    };

    const editTitleMode = ref(false);
    const titleInput = ref(null);
    const editTitle = () => {
      editTitleMode.value = true;
      nextTick(() => {
        titleInput.value.focus();
      });
    };

    return {
      cancel,
      saveContract,
      finalizeContract,
      localCurrContract,
      'contract-signing-comp': contractSigningComp,
      toggleSigModal,
      fullName,
      'contract-container': contractContainer,
      signContract,
      editTitleMode,
      'title-input': titleInput,
      editTitle,
      makeDirty,
      makeToast
    };
  },
};
</script>
