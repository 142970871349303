<template>
  <h2>Action Plan</h2>
  <div id="goals-container" class="mb-3">
    <label class="form-label">Immediate Goals</label>
    <rich-text-editor
      v-model:data="localLifePlan"
      property="goals"
      toolbar="minimal"
      :data-id="localLifePlan.symbolicId"
      container-id="goals-container"
      height="100px"
      @dirty="$emit('dirty')"
    />
  </div>
  <div id="year-1-container" class="mb-3">
    <label class="form-label">1 Year Goals</label>
    <rich-text-editor
      v-model:data="localLifePlan"
      property="year1"
      toolbar="minimal"
      :data-id="localLifePlan.symbolicId"
      container-id="year-1-container"
      height="100px"
      @dirty="$emit('dirty')"
    />
  </div>
  <div id="year-3-container" class="mb-3">
    <label class="form-label">3 Year Goals</label>
    <rich-text-editor
      v-model:data="localLifePlan"
      property="year3"
      toolbar="minimal"
      :data-id="localLifePlan.symbolicId"
      container-id="year-3-container"
      height="100px"
      @dirty="$emit('dirty')"
    />
  </div>
  <div id="year-5-container" class="mb-3">
    <label class="form-label">5 Year Goals</label>
    <rich-text-editor
      v-model:data="localLifePlan"
      property="year5"
      toolbar="minimal"
      :data-id="localLifePlan.symbolicId"
      container-id="year-5-container"
      height="100px"
      @dirty="$emit('dirty')"
    />
  </div>
  <div id="year-10-container" class="mb-3">
    <label class="form-label">10 Year Goals</label>
    <rich-text-editor
      v-model:data="localLifePlan"
      property="year10"
      toolbar="minimal"
      :data-id="localLifePlan.symbolicId"
      container-id="year-10-container"
      height="100px"
      @dirty="$emit('dirty')"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import RichTextEditor from '../../../shared/components/RichTextEditor.vue';
export default {
  components: {
    RichTextEditor,
  },
  props: {
    lifePlan: {
      type: Object,
      required: true,
    },
  },
  emits: ['dirty', 'update:lifePlan'],
  setup(props, context) {
    const localLifePlan = computed({
      get: () => props.lifePlan,
      set: (value) => context.emit('update:lifePlan', value),
    });

    return { localLifePlan };
  },
};
</script>
