<template>
    <div class="container mb-8">
        <div class="row  align-items-center mb-4">
            
            <client-menu-large v-if="isLargeScreen"></client-menu-large>
            <client-menu-small v-else></client-menu-small>
           <!-- <patient-honey-comb-map></patient-honey-comb-map> -->
      <!-- <div class="row row-cols-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-6 align-items-center mb-3"> -->
        <!-- <div class="d-flex container-fluid  justify-content-center align-items-center"> -->
            <!-- <div> -->
            
</div>

            
    <!-- </div> -->
             <!-- </div> -->
        <!-- <div class="col p-2 text-center d-grid"><button class="btn btn-primary" type="button" :disabled="$route.name == 'patient-home'" @click="$router.push({ name: 'patient-home' })">Home</button></div>
        <div class="col p-2 text-center d-grid"><a class="btn btn-primary" href="https://bhealthyforlife.com" >BHFL.com</a></div>
        <div class="col p-2 text-center d-grid"><a class="btn btn-primary" href="https://www.recovery4lifellc.com" >RFL.com</a></div> -->
        <!-- Coming Soon -->
        <!-- <tooltip-link display-text="Emergency" title-text="Emergency Resources" href="https://www.recovery4lifellc.com/emergency-resources"  :container-classes="['col', 'p-2', 'text-center', 'd-grid']" :button-classes="['btn', 'btn-danger']" /> -->
        <!-- Coming Soon -->
        <!-- <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><a class="btn btn-primary" href="https://www.therapyportal.com/p/bhealthyforlife/" >Telehealth</a></div> -->

        <!--  -->
        <!-- <div class="col p-2 text-center d-grid"><button class="btn btn-primary" type="button" @click="logout">Log out</button></div>
        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'patient-journal' })">Journal</button></div>
        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'todo-calendar' })">Schedule</button></div>
        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'patient-msg' })">Messages</button></div>
        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'patient-diversions' })">Coping Skills</button></div> -->
        <!-- <div class="col p-2 text-center d-grid"><a class="btn btn-primary" href="https://www.therapyportal.com/p/bhealthyforlife/" >TherapyNotes</a></div> -->
        <!-- Coming Soon -->
        <!-- <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'patient-lifeplan' })">Life Plan</button></div>
        <div class="col p-2 px-md-2 py-lg-3 text-center d-grid"><button class="btn btn-primary" type="button" @click="$router.push({ name: 'patient-contracts' })">Contracts</button></div> -->
        <!-- <tooltip-link display-text="BeeHive" :container-classes="['col', 'p-2', 'text-center', 'd-grid']" href="https://bhealthy.cloudu.com/Course/Start?courseID=87&lessonId=293"/> -->
        
        <router-view></router-view>
    </div>
    <!-- <div class="row">
        <router-view></router-view>
    </div> -->
</template>

<script>

import { inject } from 'vue'
import { useStore } from 'vuex'
import authLib from '@/services/lib/auth0';
import  ClientMenuLarge  from '../menus/ClientMenuLarge.vue'
import  ClientMenuSmall  from '../menus/ClientMenuSmall.vue'
import { useMediaQuery } from '@vueuse/core'
//import PatientHoneyCombMap from './PatientHoneyCombMap.vue';
//import TooltipLink from '@/views/bh2/shared/components/TooltipLink.vue'

export default {
    components: {
        //TooltipLink
        //PatientHoneyCombMap
        ClientMenuLarge,
        ClientMenuSmall
    },
    setup() {
        const api = inject('api')
        const store = useStore()
        const isLargeScreen  = useMediaQuery('(min-width: 768px)').value;
        const is_sso_mode = process.env.VUE_APP_LOGIN_SSO == 'true';
        const logout = async () => {
            store.dispatch('logOut');
            api.setAuth(null);
            if (is_sso_mode) {
                await authLib.doLogout();
            }
        };

        return {
            logout,
            isLargeScreen
        }
    },
}
</script>

<style scoped>
.icon-pointer { cursor: pointer;}
/* .background-honeycomb{fill: #D4A168;} */
/* .background-hexagon-tile{ stroke: #E7D9AC; stroke-width:6;stroke-miterlimit:10; cursor: grab ; } */
</style>