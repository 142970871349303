<template>
  <div id="collapse-A" class="collapse show">
    <div>
      <main>
        <personal-contract v-if="viewContract" v-model:viewContract="viewContract"
          v-model:currContract="currContract.data" @save="saveContract"></personal-contract>
        <div v-else ref="contract-nav-container" class="py-3 px-3 shadow">
          <div class="row mb-4 align-items-center">
            <div class="col">
              <h1>My Personal Contracts</h1>
            </div>
            <div class="col-md-4">
              <div class="input-group">
                <input
                  id="nav-title-srch-txt"
                  v-model="filter"
                  type="search"
                  class="form-control"
                  placeholder="Search Contracts"
                  @keyup.enter="srchTitle"
                />
                <button
                  id="nav-title-srch-btn"
                  class="btn btn-secondary"
                  type="button"
                  @click="srchTitle"
                >
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-hover table-striped align-middle lh-sm border-3 border-bottom">
                  <thead>
                    <tr>
                      <th scope="col">Contract Title</th>
                      <th scope="col">Saved</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="contract in list" :key="contract.id" @click="loadContract(contract)">
                      <td>{{ contract.title }}</td>
                      <td class="small">{{ contract.updatedDt }}</td>
                      <td class="text-success">
                        <i v-if="contract.isSigned" class="mx-auto fas fa-file-signature"></i>
                      </td>
                    </tr>
                    <tr v-if="list.length == 0">
                      <td colspan="3" class="text-center">
                        No contracts found.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <tbl-page-seln v-model="page_num" :total-pages="totalPages"
              class="col-sm-6 order-sm-2 d-sm-flex flex-row-reverse" @updated="loadPage"></tbl-page-seln>
            <div class="col-sm-6 text-center text-sm-start">
              <button id="new-contract-btn" class="btn btn-sm btn-outline-secondary" @click="createContract">
                Start New Contract
              </button>
            </div>

          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  inject,
  onMounted,
  ref,
  reactive,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import stdLocalTime from '../../../../../services/lib/localTime';
import TblPageSeln from '../../../shared/components/TblPageSeln.vue';
import PersonalContract from './PersonalContract.vue';

export default {
  components: {
    TblPageSeln,
    PersonalContract,
  },
  emits: ['contract-nav-close'],
  setup() {
    const api = inject('api');
    const contractsApi = api.contractsApi();
    const loading = inject('$loading');
    const list = ref([]);
    const page_size = ref(5);
    const page_num = ref(1);
    const filter = ref('');
    const totalPages = ref(0);

    const route = useRoute();

    const contractNavContainer = ref(null);
    const loadPage = async () => {
      let loader = loading.show({
        container: contractNavContainer.value,
        isFullPage: false,
      });
      const response = await contractsApi.getContractList(
        true,
        page_num.value - 1,
        page_size.value,
        filter.value.trim()
      );
      response.data.data.forEach((e) => {
        e.updatedDt = stdLocalTime(e.createdOn);
      });
      list.value = response.data.data;
      totalPages.value = response.data.totalPages;
      loader.hide();
      if (list.value.length == 0 && !filter.value && page_num.value == 1) {
        createContract();
      }
      if (route.query.cid) {
        loadContract({ id: route.query.cid });
      }
    };

    onMounted(async () => {
      await loadPage();
    });

    const srchTitle = async () => {
      page_num.value = 1;
      await loadPage();
    };

    const loadContract = async (contract) => {
      let loader = loading.show({
        container: contractNavContainer.value,
        isFullPage: false,
      });
      const response = await contractsApi.getContract(contract.id);
      response.data.contract =
        response.data.contract === null
          ? '<p><br></p>'
          : response.data.contract;
      response.data.appendix =
        response.data.appendix === null
          ? '<p><br></p>'
          : response.data.appendix;
      response.data.isDirty = false;
      currContract.data = response.data;
      viewContract.value = true;
      loader.hide();
    };

    const viewContract = ref(false);
    const currContract = reactive({ data: { id: -1, isDirty: false } });
    const createContract = async () => {
      const response = await contractsApi.createContract();
      response.data.isDirty = false;
      currContract.data = response.data;
      viewContract.value = true;
    };

    const saveContract = async () => {
      const response = await contractsApi.updateContract(currContract.data);
      if (currContract.data.id == 0) {
        currContract.data = response.data;
      }
      await loadPage();
    };

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!currContract.data.isDirty) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    // checks for unsaved changes before intra-app routing
    const store = useStore();
    onBeforeRouteLeave((to, from, next) => {
      if (currContract.data.isDirty && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      currContract.data.isDirty = false;
      next();
    });

    return {
      loadPage,
      srchTitle,
      list,
      page_num,
      filter,
      totalPages,
      createContract,
      'contract-nav-container': contractNavContainer,
      loadContract,
      viewContract,
      currContract,
      saveContract,
    };
  },
};
</script>
