<template>
  <div id="collapse-B" class="collapse show">
    <div ref="life-plan-container" class="card-body position-relative">
      <div class="row">
        <!-- Start Dreams -->
        <div class="col-md-6 mb-3">
          <my-dreams v-model:lifePlan="lifePlanData" @dirty="$emit('dirty')" />
        </div>
        <!-- Start Action Plan -->
        <div class="col mb-3">
          <action-plan
            v-model:lifePlan="lifePlanData"
            @dirty="$emit('dirty')"
          />
        </div>
        <div class="col-12">
          <div class="col-12 text-center">
            <button
              id="save-plans-btn"
              type="button"
              class="btn btn-primary"
              :disabled="!isDirty"
              @click="saveChanges"
            >
              Save Plans
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue';
import MyDreams from './MyDreams.vue';
import ActionPlan from './ActionPlan.vue';
import { useRouter } from 'vue-router';
export default {
  components: {
    MyDreams,
    ActionPlan,
  },
  props: {
    isDirty: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['clean', 'dirty'],
  setup(props, context) {
    const lifeplanApi = inject('api').lifeplanApi();
    const loading = inject('$loading');
    const router = useRouter();
    const lifePlanContainer = ref(null);
    const lifePlanData = ref({
      symbolicId: -1,
      goals: '',
      year1: '',
      year3: '',
      year5: '',
      year10: '',
    });

    onMounted(async () => {
      refresh();
    });

    const refresh = async () => {
      const loader = loading.show({
        container: lifePlanContainer.value,
        isFullPage: false,
      });
      const response = await lifeplanApi.getLifePlan();
      const oldId = lifePlanData.value.symbolicId;
      lifePlanData.value = response.data;
      lifePlanData.value.symbolicId = oldId + 1;
      loader.hide();
    };

    // save changes to database
    const saveChanges = async () => {
      let loader = loading.show({
        container: lifePlanContainer.value,
        isFullPage: false,
      });
      await lifeplanApi.saveLifePlan({
        millionDollarQuestion: lifePlanData.value.millionDollarQuestion,
        year1: lifePlanData.value.year1,
        year3: lifePlanData.value.year3,
        year5: lifePlanData.value.year5,
        year10: lifePlanData.value.year10,
        retirement: lifePlanData.value.retirement,
        goals: lifePlanData.value.goals,
      });
      context.emit('clean');
      loader.hide();
      router.push({ query: { tab: 'bucket-list' } });
      refresh();
    };

    return {
      lifePlanData,
      'life-plan-container': lifePlanContainer,
      saveChanges,
    };
  },
};
</script>
