<template>
  <div class="col mb-4">
    <!-- search journal entries -->
    <form class="mb-3" @submit.prevent="$emit('updated')">
      <div class="input-group mb-3">
        <div class="form-floating form-floating-group flex-grow-1">
          <input
            id="patient-filter"
            v-model="localTextFilter"
            type="text"
            class="form-control"
            name="searchPatient"
            placeholder="Find Patient"
          />
          <label for="searchPatient">Search Journal Entries</label>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          id="date-from"
          v-model="localDateFrom"
          type="date"
          class="form-control"
          placeholder="Username"
          aria-label="Username"
          :max="maxDate"
        />
        <span class="input-group-text">to</span>
        <input
          id="date-to"
          v-model="localDateTo"
          type="date"
          class="form-control"
          placeholder="Server"
          aria-label="Server"
          :max="maxDate"
        />
      </div>
      <div v-if="$route.query.myCare != 'true'" class="form-check my-auto mb-3 ms-3">
        <label class="form-check-label small" for="shared">
          Journal entry was shared
        </label>
        <input
          id="shared-only"
          v-model="localSharedOnly"
          class="form-check-input"
          type="checkbox"
        />
      </div>
      <div class="form-floating mb-3">
        <select
          id="floatingSelect"
          v-model="localTypeFilter"
          class="form-select"
          aria-label="Floating label select example"
        >
          <option value="">Select an Entry Type</option>
          <option value="DailyThought">My Thoughts</option>
          <option v-if="$route.query.myCare != 'true'" value="ThoughtProfPrompt">My Thoughts (Pro Prompts)</option>
          <option value="SystemPrompt">Question</option>
          <option value="Gratitude">Gratitude</option>
          <option value="Trauma">Trauma</option>
          <option v-if="$route.query.myCare != 'true'" value="TraumaProfPrompt">Trauma (Pro Prompts)</option>
        </select>
        <label for="floatingSelect">Journal Entry Type</label>
      </div>
      <button
        id="search-journals-btn"
        type="submit"
        class="btn btn-success float-end"
      >
        Find Journal Entries
      </button>
    </form>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    textFilter: {
      type: String,
      required: true,
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
    sharedOnly: {
      type: Boolean,
      required: true,
    },
    typeFilter: {
      type: String,
      required: true,
    },
  },
  emits: [
    'updated',
    'update:textFilter',
    'update:dateFrom',
    'update:dateTo',
    'update:sharedOnly',
    'update:typeFilter',
  ],
  setup(props, context) {
    const localTextFilter = computed({
      get: () => props.textFilter,
      set: (value) => context.emit('update:textFilter', value),
    });

    const localDateFrom = computed({
      get: () => props.dateFrom,
      set: (value) => context.emit('update:dateFrom', value),
    });

    const localDateTo = computed({
      get: () => props.dateTo,
      set: (value) => context.emit('update:dateTo', value),
    });

    const localSharedOnly = computed({
      get: () => props.sharedOnly,
      set: (value) => context.emit('update:sharedOnly', value),
    });

    const localTypeFilter = computed({
      get: () => props.typeFilter,
      set: (value) => context.emit('update:typeFilter', value),
    });

    return {
      localTextFilter,
      localDateFrom,
      localDateTo,
      localSharedOnly,
      localTypeFilter,
    };
  },
  created ()
  {
    const d = new Date()
    //for calendar
    this.maxDate =  `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
  },
};
</script>
