<template>
  <div
    :id="id"
    ref="modal-ref"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="id + 'Label'"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen-lg-down"
    >
      <div
        :ref="currEntry.entryType == entryType ? 'modalContent' : ''"
        class="modal-content position-relative"
      >
        <div
          class="modal-header text-white justify-content-start"
          :class="classes"
        >
          <i v-if="headerIcon" :class="`pe-3 fas fa-${headerIcon} fa-3x`"></i>
          <div>
            <h3 class="m-0 p-0" style="line-height: 2rem">
              {{ headerText }}
            </h3>
            <h5 class="m-0 p-0 mt-1" style="line-height: 1.6rem">
              <slot name="prompt"></slot>
            </h5>
          </div>
        </div>
        <form>
          <div class="modal-body pt-0">
            <div class="d-flex justify-content-between align-items-center py-2">
              <button
                v-if="entryType == 'SystemPrompt'"
                id="new-sys-prompt-btn"
                type="button"
                class="btn btn-sm text-primary"
                @click="$emit('new-sys-prompt')"
              >
                <i class="fas fa-sync-alt"></i> Load New Prompt
              </button>
              <div
                v-if="$route.query.myCare != 'true'"
                class="form-check form-switch d-flex justify-content-end py-2"
              >
                <input
                  :id="`${id}-share-toggle`"
                  v-model="currEntryIsShared.isShared"
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  @change="$emit('dirty')"
                />
                <label
                  :id="`${id}-share-label`"
                  class="form-check-label ps-2"
                  for="flexSwitchCheckChecked"
                  >Share</label
                >
                <a
                  :id="`${id}-tooltip`"
                  href="javascript:void(0)"
                  class="d-line-block"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Sharing allows your health professional to view your response. You can stop sharing at any time."
                >
                  <i
                    tabindex="0"
                    :class="`ps-1 pb-1 text-primary fas fa-info-circle fa-xs`"
                    @blur="tooltips[0].hide()"
                  ></i>
                </a>
              </div>
            </div>
            <div :id="`${id}-rte-container`">
              <rich-text-editor
                :id="`${id}-response-textarea`"
                v-model:data="currEntryResponse"
                property="response"
                :data-id="currEntryResponse.id"
                :container-id="`${id}-rte-container`"
                height="50vh"
                @dirty="$emit('dirty')"
              ></rich-text-editor>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button
                :id="`${id}-close-modal-btn`"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                @click="$emit('clean')"
              >
                Cancel
              </button>
              <button
                :id="`${id}-save-btn`"
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
                :disabled="
                  !currEntryResponse.response ||
                  currEntryResponse.response == '<p><br></p>'
                "
                @click="saveChanges"
              >
                Save &amp; Close
              </button>
            </slot>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import initToolTip from '../../../../../services/lib/tooltipInit';
import RichTextEditor from '../../../shared/components/RichTextEditor.vue';
export default {
  components: {
    RichTextEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    headerIcon: {
      type: String,
      default: '',
    },
    headerText: {
      type: String,
      default: '',
    },
    classes: {
      type: Object,
      default: () => {},
    },
    response: {
      type: Object,
      default: () => {},
    },
    isShared: {
      type: Object,
      default: () => {},
    },
    currEntry: {
      type: Object,
      required: true,
    },
    entryType: {
      type: String,
      required: true,
    },
  },
  emits: [
    'update:response',
    'update:isShared',
    'save-clicked',
    'dirty',
    'clean',
    'new-sys-prompt',
  ],
  setup(props, context) {
    const currEntryResponse = computed({
      get: () => props.response,
      set: (value) => context.emit('update:response', value),
    });

    const currEntryIsShared = computed({
      get: () => props.isShared,
      set: (value) => context.emit('update:isShared', value),
    });

    const saveChanges = () => {
      context.emit('save-clicked');
    };

    const modalContent = ref(null);
    const modalRef = ref(null);

    const tooltips = ref([]);

    onMounted(() => {
      tooltips.value = initToolTip(modalRef.value);
    });

    return {
      currEntryResponse,
      currEntryIsShared,
      saveChanges,
      modalContent,
      'modal-ref': modalRef,
      tooltips,
    };
  },
};
</script>

<style>
.fa-tooltip-icon {
  outline: none;
}
</style>
