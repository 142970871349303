<template>
  <div class="col" @click="isExpanded = !isExpanded">
    <div
      class="p-2 p-lg-3 border border-start-3 bg-light d-flex flex-column justify-content-sm-between align-items-md-start h-100"
      :class="getBorderColor(entry.entryType)"
    >
      <div class="d-flex align-items-center">
        <div
          v-if="isProfPrompt(entry) && proId != -1"
          class="circle-profile shadow me-3 flex-shrink-0"
          :style="`background-image: url(${profPicUrl})`"
        ></div>
        <p class="mb-2 px-0 pe-md-0">
          {{ entry.journalDay }}
        </p>
      </div>
      <div>
        <p class="mb-1 px-0 pe-md-0">
          {{ getUnexpandedContent() }}
        </p>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="isExpanded" v-html="entry.response"></div>
      <!-- eslint-enable -->
      <div
        class="btn-toolbar ms-auto"
        role="toolbar"
        aria-label="Toolbar with button groups"
      >
        <div class="btn-group me-1" role="group" aria-label="First group">
          <button
            v-if="
              entry.entryType != 'Trauma' &&
              entry.entryType != 'TraumaProfPrompt'
            "
            :id="`delete-entry-${entry.journalId}-${entry.id}-btn`"
            type="button"
            class="btn btn-sm"
            data-bs-toggle="modal"
            :data-bs-target="`#deleteConfirmation-${entry.journalId}-${entry.id}`"
            @click.stop
          >
            <i class="fas fa-trash-alt"></i>
          </button>
          <button
            v-else
            :id="`burn-trauma-${entry.journalId}-${entry.id}-btn`"
            type="button"
            class="btn btn-sm"
            data-bs-toggle="modal"
            :data-bs-target="`#deleteConfirmation-${entry.journalId}-${entry.id}`"
            @click.stop
          >
            <i class="fas fa-burn text-danger"></i>
          </button>
          <div
            v-if="$route.query.myCare != 'true'"
            class="form-check my-auto ms-2"
          >
            <input
              :id="`shared-checkbox-${entry.journalId}-${entry.id}`"
              v-model="localIsShared"
              class="form-check-input"
              type="checkbox"
              @change="
                $emit('is-shared-updated', {
                  journalId: 0,
                  entryId: entry.id,
                  isShared: localIsShared,
                })
              "
              @click.stop
            />
            <label class="form-check-label small" for="shared"> Shared </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Confirmation -->
  <div
    :id="`deleteConfirmation-${entry.journalId}-${entry.id}`"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="deleteConfirmationLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div ref="confirmDeleteModal" class="modal-content position-relative">
        <div class="modal-body p-5">
          <p class="h3 mb-4">
            Are you sure you want to {{ deleteVerb }} this entry?
          </p>
          <div class="text-end">
            <button
              :id="`cancel-delete-btn-${entry.journalId}-${entry.id}`"
              type="button"
              class="btn btn-secondary m-2"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              v-if="deleteVerb == 'burn'"
              :id="`confirm-burn-btn-${entry.journalId}-${entry.id}`"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="$emit('burn-trauma', entry)"
            >
              Burn
            </button>
            <button
              v-else
              :id="`confirm-delete-btn-${entry.journalId}-${entry.id}`"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="$emit('delete-entry', entry)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import stdLocalTime from '../../../../../services/lib/localTime';

export default {
  props: {
    entry: {
      type: Object,
      required: true,
    },
    proId: {
      type: Number,
      required: true,
    },
  },
  emits: ['is-shared-updated', 'burn-trauma', 'delete-entry'],
  setup(props) {
    const localIsShared = ref(props.entry.isShared);
    const isExpanded = ref(false);

    const getUnexpandedContent = () => {
      const entry = props.entry;
      if(entry.prompt) return entry.prompt;

      if(isExpanded.value) return ''

      // in case of entry with no prompt value, display a part of the entry.response when card is not expanded
      const tempElement = document.createElement('div');
      tempElement.innerHTML = entry.response;
      const elements = tempElement.childNodes;
      let textContent = '';
      elements.forEach((element) => {
        if (element.nodeType === Node.ELEMENT_NODE) {
          textContent += element.textContent.trim() + '\n';
        }
      });

      return textContent.slice(0, 100)
    }

    const profPicUrl = computed(() => {
      if (props.proId == -1) return '';
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${props.proId}`;
    });

    const getBorderColor = (entryType) => {
      switch (entryType) {
        case 'DailyThought':
          return 'border-primary';
        case 'ThoughtProfPrompt':
          return 'border-primary';
        case 'SystemPrompt':
          return 'border-secondary';
        case 'Gratitude':
          return 'border-success';
        case 'Trauma':
          return 'border-danger';
        case 'TraumaProfPrompt':
          return 'border-danger';
      }
      return '';
    };

    const convertToLocal = (utcTime) => {
      return stdLocalTime(utcTime);
    };

    const isProfPrompt = (entry) => {
      return (
        entry.entryType == 'ThoughtProfPrompt' ||
        entry.entryType == 'TraumaProfPrompt'
      );
    };

    const deleteVerb = computed(() => {
      if (
        props.entry.entryType == 'Trauma' ||
        props.entry.entryType == 'TraumaProfPrompt'
      ) {
        return 'burn';
      } else {
        return 'delete';
      }
    });

    return {
      profPicUrl,
      getBorderColor,
      convertToLocal,
      isProfPrompt,
      localIsShared,
      isExpanded,
      deleteVerb,
      getUnexpandedContent,
    };
  },
};
</script>
