<template>
  <div
    v-if="unsavedProEntries.length > 0"
    class="container bg-light shadow py-4 mb-5 border"
  >
    <div class="row">
      <div class="col">
        <h2 class="h4 mb-3">My Prompts</h2>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 g-2 g-lg-3">
      <div v-for="entry in unsavedProEntries" :key="entry.id" class="col">
        <button
          :id="`pro-edit-btn-${entry.id}`"
          class="
            p-3
            border border-start-3
            bg-light
            d-flex
            w-100
            text-start
            h-100
          "
          :class="entryBorderClass(entry.entryType)"
          data-bs-toggle="modal"
          :data-bs-target="targets[entry.entryType]"
          @click="viewPromptModal(entry)"
        >
          <div class="d-flex align-items-center">
            <div
              class="circle-profile shadow me-3 flex-shrink-0"
              :style="`background-image: url(${getProfileUrl(
                entry.professionalId
              )})`"
            ></div>
          </div>
          <div class="small">
            {{ entry.professionalName }} Asks:<span
              class="h6 d-block p-0 m-0"
              >{{ entry.prompt }}</span
            >
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { entryBorderClass } from '../../../../../services/lib/journallingLib';

export default {
  props: {
    journal: {
      type: Object,
      required: true,
    },
  },
  emits: ['open-modal'],
  setup(props, context) {
    const viewPromptModal = (entry) => {
      context.emit('open-modal', entry);
    };

    const unsavedProEntries = computed(() => {
      return props.journal.data.entries.filter(
        (entry) =>
          (entry.entryType == 'ThoughtProfPrompt' ||
            entry.entryType == 'TraumaProfPrompt') &&
          !entry.response
      );
    });

    const targets = {
      ThoughtProfPrompt: '#proThoughtPrompt',
      TraumaProfPrompt: '#proTraumaPrompt',
    };

    const getProfileUrl = (profId) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${profId}`;
    };

    return {
      unsavedProEntries,
      viewPromptModal,
      entryBorderClass,
      targets,
      getProfileUrl,
    };
  },
};
</script>

<style scoped>
img.profile-pic {
  height: 3em;
}
</style>
