<template>
  <div v-if="savedEntries.length > 0" class="container pb-5">
    <div class="row">
      <div class="col">
        <h2 class="h4 mb-3">Saved Journal Entries</h2>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 g-lg-3">
      <div v-for="savedEntry in savedEntries" :key="savedEntry.id" class="col">
        <div
          class="p-2 p-lg-3 border border-start-3 bg-light d-flex flex-column justify-content-sm-between align-items-md-start h-100"
          :class="entryBorderClass(savedEntry.entryType)"
        >
          <div class="align-items-start">
            <div
              v-if="savedEntry.professionalId"
              class="circle-profile shadow me-3 flex-shrink-0"
              :style="`background-image: url(${getProfileUrl(
                savedEntry.professionalId
              )})`"
            ></div>
            <p class="mb-0 px-2 pe-md-0">
              {{ getFormattedDateTime(savedEntry.lastUpdatedUtc)
              }}<br v-if="savedEntry.prompt" />{{ savedEntry.prompt }}
            </p>
            <div
              class="mt-1 px-2 pe-md-0 journal-entry-response"
              v-if="savedEntry.response"
              v-html="savedEntry.response"
            ></div>
          </div>
          <div
            class="btn-toolbar ms-auto"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div class="btn-group" role="group" aria-label="First group">
              <button
                :id="`edit-btn-${savedEntry.id}`"
                type="button"
                class="btn btn-sm"
                data-bs-toggle="modal"
                :data-bs-target="targets[savedEntry.entryType]"
                @click="editEntry(savedEntry)"
              >
                <i class="fas fa-edit"></i>
              </button>
              <button
                v-if="
                  savedEntry.entryType == 'Trauma' ||
                  savedEntry.entryType == 'TraumaProfPrompt'
                "
                :id="`burn-btn-${savedEntry.id}`"
                type="button"
                class="btn btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#deleteConfirmation"
                @click="editEntry(savedEntry)"
              >
                <i class="fas fa-burn text-danger"></i>
              </button>
              <button
                v-else
                :id="`delete-btn-${savedEntry.id}`"
                type="button"
                class="btn btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#deleteConfirmation"
                @click="editEntry(savedEntry)"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
              <div
                v-if="$store.getters.userType == 'patient'"
                class="form-check my-auto ms-2"
              >
                <input
                  :id="`shared-checkbox-${savedEntry.id}`"
                  v-model="savedEntry.isShared"
                  class="form-check-input"
                  type="checkbox"
                  @change="saveShareToggle(savedEntry)"
                />
                <label class="form-check-label small" for="shared">
                  Share
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Confirmation -->
  <div
    id="deleteConfirmation"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="deleteConfirmationLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div ref="confirmDeleteModal" class="modal-content position-relative">
        <div class="modal-body p-5">
          <p class="h3 mb-4">
            Are you sure you want to {{ deleteVerb }} this entry?
          </p>
          <div class="text-end">
            <button
              id="cancel-delete-btn"
              type="button"
              class="btn btn-secondary me-2"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              v-if="deleteVerb == 'burn'"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="burnTrauma"
            >
              Burn
            </button>
            <button
              v-else
              id="confirm-delete-btn"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="deleteEntry"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import stdLocalTime from '../../../../../services/lib/localTime';
import { entryBorderClass } from '../../../../../services/lib/journallingLib';

export default {
  props: {
    journal: {
      type: Object,
      required: true,
    },
    currEntry: {
      type: Object,
      required: true,
    },
  },
  emits: ['edit-entry', 'delete-entry', 'share-toggled', 'burn-trauma'],
  setup(props, context) {
    const confirmDeleteModal = ref(null);

    const savedEntries = computed(() => {
      return props.journal.data.entries.filter((entry) => entry.response);
    });

    const getFormattedDateTime = (dateTime) => {
      return stdLocalTime(dateTime);
    };

    const getContent = (savedEntry) => {
      if(savedEntry.prompt) return savedEntry.prompt;

      // in case of entry with no prompt value, display a part of the entry.response
      const tempElement = document.createElement('div');
      tempElement.innerHTML = savedEntry.response;
      const elements = tempElement.childNodes;
      let textContent = '';
      elements.forEach((element) => {
        if (element.nodeType === Node.ELEMENT_NODE) {
          textContent += element.textContent.trim() + '\n';
        }
      });

      return textContent.slice(0, 100)
    }

    const deleteVerb = computed(() => {
      if (
        props.currEntry.entryType == 'Trauma' ||
        props.currEntry.entryType == 'TraumaProfPrompt'
      ) {
        return 'burn';
      } else {
        return 'delete';
      }
    });

    const targets = {
      DailyThought: '#myThoughtsPrompt',
      SystemPrompt: '#systemPrompt',
      Gratitude: '#gratitudePrompt',
      Trauma: '#traumaPrompt',
      ThoughtProfPrompt: '#proThoughtPrompt',
      TraumaProfPrompt: '#proTraumaPrompt',
    };

    const editEntry = (entry) => {
      context.emit('edit-entry', entry);
    };

    const deleteEntry = () => {
      context.emit('delete-entry');
    };

    const burnTrauma = () => {
      context.emit('burn-trauma');
    };

    const saveShareToggle = (entry) => {
      context.emit('share-toggled', entry);
    };

    const getProfileUrl = (profId) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${profId}`;
    };

    return {
      confirmDeleteModal,
      savedEntries,
      getFormattedDateTime,
      entryBorderClass,
      deleteVerb,
      targets,
      editEntry,
      deleteEntry,
      burnTrauma,
      saveShareToggle,
      getProfileUrl,
      getContent,
    };
  },
};
</script>

<style scoped>
img.profile-pic {
  height: 3em;
}
</style>
