<template>
  <div id="collapse-A" class="collapse show">
    <div class="card-body">
      <h5>Suggested Coping Skill</h5>
      <div class="alert alert-info mt-3" role="alert">
        <h1>{{ diversion }}</h1>
        <div class="text-center">
          <button
            id="add-diversion-btn"
            class="btn btn-lg btn-success m-2"
            :disabled="!diversion"
            @click="addDiversion()"
          >
            Will Do
          </button>
          <button
            id="next-diversion-btn"
            class="btn btn-lg btn-danger m-2"
            @click="refresh()"
          >
            Give Me Another
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const genApi = inject('api').generalDataApi();
    const router = useRouter();
    const patientApi = inject('api').patientApi();
    const diversion = ref('');

    const refresh = async () => {
      const response = await genApi.getADiversion();
      diversion.value = response.data.text;
    };

    onMounted(async () => {
      await refresh();
    });

    const addDiversion = async () => {
      await patientApi.addDiversionTask({ text: diversion.value });
      router.push({ name: 'todo-calendar' });
    };

    return {
      diversion,
      refresh,
      addDiversion,
    };
  },
};
</script>
