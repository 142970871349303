<template>
  <div id="collapse-A" class="collapse show">
    <div ref="journal-container" class="card-body position-relative">
      <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3 text-center">
        <div class="col">
          <button
            id="open-thoughts-btn-large"
            class="btn btn-lg btn-primary btn-block h-100 w-100 py-4 px-2"
            data-bs-toggle="modal"
            data-bs-target="#myThoughtsPrompt"
            @click="getDailyThought"
          >
            <i class="fas fa-pen-nib fa-2x"></i>
            <br />My Thoughts
          </button>
        </div>

        <div class="col">
          <button
            id="open-system-prompt-btn-large"
            class="btn btn-lg btn-secondary btn-block h-100 w-100 py-4 px-2"
            data-bs-toggle="modal"
            data-bs-target="#systemPrompt"
            @click="createNewSystemPrompt"
          >
            <i class="fas fa-comment fa-2x"></i>
            <br />Answer a Question
          </button>
        </div>

        <div class="col">
          <button
            id="open-gratitude-btn-large"
            class="btn btn-lg btn-success btn-block h-100 w-100 py-4 px-2"
            data-bs-toggle="modal"
            data-bs-target="#gratitudePrompt"
            @click="getGratitude"
          >
            <i class="fas fa-heart fa-2x"></i>
            <br />Gratitude
          </button>
        </div>

        <div class="col">
          <button
            id="open-trauma-btn-large"
            class="btn btn-lg btn-danger btn-block h-100 w-100 py-4 px-2"
            data-bs-toggle="modal"
            data-bs-target="#traumaPrompt"
            @click="getTrauma"
          >
            <i class="fas fa-burn fa-2x"></i>
            <br />Trauma
          </button>
        </div>
      </div>
    </div>

    <!-- Professional Prompts -->
    <pro-prompts :journal="journal" @open-modal="changeCurrEntry"></pro-prompts>

    <!-- Saved Entries -->
    <journal-entries
      ref="delete-modal-container"
      :journal="journal"
      :curr-entry="currEntry"
      @edit-entry="changeCurrEntry"
      @delete-entry="deleteEntry"
      @share-toggled="saveShareToggle"
      @burn-trauma="burnTrauma"
    ></journal-entries>

    <!-- My Thoughts Prompt Modal -->
    <journal-modal
      id="myThoughtsPrompt"
      :ref="
        (el) => {
          modalContainers['DailyThought'] = el;
        }
      "
      v-model:response="currEntry"
      v-model:isShared="currEntry"
      header-icon="pen-nib"
      header-text="Daily Thoughts"
      :curr-entry="currEntry"
      entry-type="DailyThought"
      :classes="{ 'bg-primary': true }"
      @dirty="makeDirty"
      @clean="makeClean"
      @save-clicked="saveChanges"
    >
    </journal-modal>
    <!-- Random Prompt Modal -->
    <journal-modal
      id="systemPrompt"
      :ref="
        (el) => {
          modalContainers['SystemPrompt'] = el;
        }
      "
      v-model:response="currEntry"
      v-model:isShared="currEntry"
      header-icon="comment"
      header-text="Answer a Question:"
      :curr-entry="currEntry"
      entry-type="SystemPrompt"
      :classes="{ 'bg-secondary': true }"
      @dirty="makeDirty"
      @clean="makeClean"
      @save-clicked="saveChanges"
      @new-sys-prompt="createNewSystemPrompt"
    >
      <template #prompt>
        {{ currEntry.prompt }}
      </template>
    </journal-modal>
    <!-- Gratitude Prompt Modal -->
    <journal-modal
      id="gratitudePrompt"
      :ref="
        (el) => {
          modalContainers['Gratitude'] = el;
        }
      "
      v-model:response="currEntry"
      v-model:isShared="currEntry"
      header-icon="heart"
      header-text="Gratitude"
      :curr-entry="currEntry"
      entry-type="Gratitude"
      :classes="{ 'bg-success': true }"
      @dirty="makeDirty"
      @clean="makeClean"
      @save-clicked="saveChanges"
    >
    </journal-modal>

    <!-- Trauma Prompt Modal -->
    <journal-modal
      id="traumaPrompt"
      :ref="
        (el) => {
          modalContainers['Trauma'] = el;
        }
      "
      v-model:response="currEntry"
      v-model:isShared="currEntry"
      header-icon="burn"
      header-text="Trauma"
      :curr-entry="currEntry"
      entry-type="Trauma"
      :classes="{ 'bg-danger': true }"
      @dirty="makeDirty"
      @clean="makeClean"
    >
      <template #footer>
        <button
          v-if="!burnPending"
          id="traumaPrompt-close-modal-btn"
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          @click="makeClean"
        >
          Cancel
        </button>
        <button
          v-if="!burnPending"
          id="traumaPrompt-save-btn"
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          :disabled="!currEntry.response || currEntry.response == '<p><br></p>'"
          @click="saveChanges"
        >
          Save &amp; Close
        </button>

        <button
          v-if="!burnPending && currEntry.id != 0"
          id="traumaPrompt-burn-btn-large"
          :disabled="currEntry.id == 0 || isDirty"
          type="button"
          class="btn btn-danger"
          @click="burnPending = true"
        >
          <i class="fas fa-burn fa-lg"></i>
          Burn It!
        </button>
        <button
          v-if="burnPending"
          id="confirm-burn-btn"
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          @click="burnTrauma"
        >
          <i class="fas fa-burn fa-lg"></i>
          Confirm
        </button>
        <button
          v-if="burnPending"
          id="cancel-burn-btn"
          type="button"
          class="btn btn-secondary"
          @click="burnPending = false"
        >
          Cancel
        </button>
      </template>
    </journal-modal>

    <!-- Professional Thought Prompt Modal -->
    <journal-modal
      id="proThoughtPrompt"
      :ref="
        (el) => {
          modalContainers['ThoughtProfPrompt'] = el;
        }
      "
      v-model:response="currEntry"
      v-model:isShared="currEntry"
      header-icon="comment-dots"
      header-text="My Thoughts:"
      :curr-entry="currEntry"
      entry-type="ThoughtProfPrompt"
      :classes="{ 'bg-primary': true }"
      @save-clicked="saveChanges"
      @dirty="makeDirty"
      @clean="makeClean"
    >
      <template #prompt> {{ currEntry.prompt }} </template>
    </journal-modal>
    <!-- Professional Trauma Prompt Modal -->
    <journal-modal
      id="proTraumaPrompt"
      :ref="
        (el) => {
          modalContainers['TraumaProfPrompt'] = el;
        }
      "
      v-model:response="currEntry"
      v-model:isShared="currEntry"
      header-icon="comment-dots"
      header-text="Trauma:"
      :curr-entry="currEntry"
      entry-type="TraumaProfPrompt"
      :classes="{ 'bg-danger': true }"
      @dirty="makeDirty"
      @clean="makeClean"
    >
      <template #prompt> {{ currEntry.prompt }} </template>
      <template #footer>
        <button
          v-if="!burnPending"
          id="proTraumaPrompt-close-modal-btn"
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          @click="makeClean"
        >
          Cancel
        </button>
        <button
          v-if="!burnPending"
          id="proTraumaPrompt-save-btn"
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          :disabled="!currEntry.response || currEntry.response == '<p><br></p>'"
          @click="saveChanges"
        >
          Save &amp; Close
        </button>
        <button
          v-if="!burnPending"
          id="proTraumaPrompt-burn-btn-large"
          type="button"
          class="btn btn-danger"
          :disabled="
            !currEntry.response ||
            currEntry.response == '<p><br></p>' ||
            isDirty
          "
          @click="burnPending = true"
        >
          <i class="fas fa-burn fa-lg"></i>
          Burn It!
        </button>
        <button
          v-if="burnPending"
          id="confirm-burn-btn"
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          @click="burnTrauma"
        >
          <i class="fas fa-burn fa-lg"></i>
          Confirm
        </button>
        <button
          v-if="burnPending"
          id="cancel-burn-btn"
          type="button"
          class="btn btn-secondary"
          @click="burnPending = false"
        >
          Cancel
        </button>
      </template>
    </journal-modal>
    <burn-animation></burn-animation>
  </div>
</template>

<script>
import JournalModal from './JournalModal.vue';
import JournalEntries from './JournalEntries.vue';
import ProPrompts from './ProPrompts.vue';
import BurnAnimation from './BurnAnimation.vue';
import {
  inject,
  onBeforeMount,
  onMounted,
  ref,
  reactive,
  onBeforeUnmount,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';
export default {
  components: {
    JournalModal,
    JournalEntries,
    ProPrompts,
    BurnAnimation,
  },
  setup() {
    const journalApi = inject('api').journalApi();
    const loading = inject('$loading');

    // initial page setup (get initial data to render and add a beforeunload event listener)
    const journal = reactive({ data: { id: -1, day: '', entries: [] } });
    const currEntry = ref({
      id: -1,
      entryType: '',
      prompt: '',
      response: '<p><br></p>',
      isShared: false,
    });

    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    const journalContainer = ref(null);
    onMounted(async () => {
      const loader = loading.show({
        container: journalContainer.value,
        isFullPage: false,
      });
      const response = await journalApi.getTodaysJournal();
      journal.data = response.data;
      loader.hide();
    });

    const modalContainers = reactive({
      DailyThought: ref(null),
      Gratitude: ref(null),
      Trauma: ref(null),
      SystemPrompt: ref(null),
      ThoughtProfPrompt: ref(null),
      TraumaProfPrompt: ref(null),
    });

    // get existing promptless thought entry if one exists, else get new entry
    const getDailyThought = () => {
      const result = journalApi.getDailyThought(journal.data);
      if (!result.response) {
        result.response = '<p><br></p>';
      }
      currEntry.value = result;
    };

    // get existing gratitude entry if one exists, else get new entry
    const getGratitude = () => {
      const result = journalApi.getGratitude(journal.data);
      if (!result.response) {
        result.response = '<p><br></p>';
      }
      currEntry.value = result;
    };

    // get existing promptless trauma entry if one exists, else get new entry
    const getTrauma = () => {
      const result = journalApi.getTrauma(journal.data);
      if (!result.response) {
        result.response = '<p><br></p>';
      }
      currEntry.value = result;
    };

    // get most recently edited system prompt entry if one exists, else get a new random system prompt entry
    const getSystemPrompt = async () => {
      const loader = loading.show({
        container: modalContainers['SystemPrompt'].modalContent,
        isFullPage: false,
        opacity: 0,
      });
      currEntry.value = await journalApi.getSystemPrompt(journal.data);
      if (!currEntry.value.response) {
        currEntry.value.response = '<p><br></p>';
      }
      loader.hide();
    };

    // generate random system prompt
    const createNewSystemPrompt = async () => {
      const loader = loading.show({
        container: modalContainers['SystemPrompt'].modalContent,
        isFullPage: false,
        opacity: 0,
      });
      currEntry.value = await journalApi.createNewSystemPrompt();
      loader.hide();
    };

    // changes entry rendered in current modal to a particular entry
    const changeCurrEntry = (entry) => {
      if (
        entry.entryType == 'SystemPrompt' ||
        entry.entryType == 'ThoughtProfPrompt' ||
        entry.entryType == 'TraumaProfPrompt'
      ) {
        const entryCopy = JSON.parse(JSON.stringify(entry));
        if (!entryCopy.response) {
          entryCopy.response = '<p><br></p>';
        }
        currEntry.value = entryCopy;
      } else if (entry.entryType == 'DailyThought') {
        getDailyThought();
      } else if (entry.entryType == 'Gratitude') {
        getGratitude();
      } else {
        getTrauma();
      }
    };

    // save updated entry response
    const saveChanges = async () => {
      const loader = loading.show({
        container: modalContainers[currEntry.value.entryType].modalContent,
        isFullPage: false,
        opacity: 0,
      });
      const response = await journalApi.updateEntry(
        journal.data.id,
        currEntry.value
      );
      journal.data = response.data;
      makeClean();
      loader.hide();
    };

    // delete an entry
    const deleteModalContainer = ref(null);
    const deleteEntry = async () => {
      const loader = loading.show({
        container: deleteModalContainer.value.confirmDeleteModal,
        isFullPage: false,
        opacity: 0,
      });
      const response = await journalApi.deleteEntry(
        journal.data.id,
        currEntry.value
      );
      makeClean();
      journal.data = response.data;
      loader.hide();
    };

    // burn trauma
    const burnPending = ref(false);
    const burnTrauma = async () => {
      const burnModal = new Modal(document.getElementById('burnModal'), {
        keyboard: false,
      });
      burnModal.show();
      const response = await journalApi.burnTrauma(
        journal.data.id,
        currEntry.value
      );
      // disable burning animation after 5000ms or when the response has been received from backend, whichever takes longer
      setTimeout(() => {
        if (response.data.entries) {
          burnModal.hide();
          burnPending.value = false;
          journal.data = response.data;
        }
      }, 5000);
    };

    // share toggle was switched, so save change (no loading animation)
    const saveShareToggle = async (entry) => {
      const response = await journalApi.updateEntry(journal.data.id, entry);
      journal.data = response.data;
    };

    // check for unsaved changes before leaving app
    const isDirty = ref(false);
    let preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    // track whether there currently are unsaved changes
    const makeDirty = () => {
      isDirty.value = true;
    };
    const makeClean = () => {
      currEntry.value = {
        id: -1,
        entryType: '',
        prompt: '',
        response: '<p><br></p>',
        isShared: false,
      };
      isDirty.value = false;
    };

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before intra-app routing
    const store = useStore();
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      next();
    });

    return {
      journal,
      currEntry,
      'journal-container': journalContainer,
      modalContainers,
      getDailyThought,
      getGratitude,
      getTrauma,
      getSystemPrompt,
      createNewSystemPrompt,
      changeCurrEntry,
      saveChanges,
      'delete-modal-container': deleteModalContainer,
      deleteEntry,
      burnPending,
      burnTrauma,
      saveShareToggle,
      makeDirty,
      makeClean,
      isDirty,
    };
  },
};
</script>

<style>
body.modal-open {
  overflow: visible;
}
</style>
