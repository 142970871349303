<template>
  <!-- <div class="col mt-1 mb-2">
    <a class="btn btn-secondary" href="https://bhealthy.cloudu.com/Course/Start?courseID=80&lessonId=298" >Learn More</a>
  </div> -->
  <div ref="contracts-view" class="col responsive-tabs">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'contract-navigation' }"
          style="cursor: pointer"
          @click="setTab('contract-navigation')"
          >My Contracts</a
        >
      </li>
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'archived-contracts' }"
          style="cursor: pointer"
          @click="setTab('archived-contracts')"
          >Archived Contracts</a
        >
      </li> -->
    </ul>

    <!-- Accordion items - headers hidden on desktop view-->
    <div id="content" class="tab-content">
      <!-- tab one -->
      <div
        id="pane-A"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'contract-navigation' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'contract-navigation' }"
              style="cursor: pointer"
              @click="setTab('contract-navigation')"
              >Contracts</a
            >
          </h5>
        </div>
        <contract-navigation v-if="currTab == 'contract-navigation'" />
      </div>

      <!-- tab two -->
      <!-- <div
        id="pane-B"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'archived-contracts' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'archived-contracts' }"
              style="cursor: pointer"
              @click="setTab('archived-contracts')"
              >Second Tab</a
            >
          </h5>
        </div>
        <archived-contracts v-if="currTab == 'archived-contracts'" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ContractNavigation from './my-contracts/ContractNavigation.vue';
// import ArchivedContracts from './archived-contracts/ArchivedContracts.vue';

export default {
  components: {
    ContractNavigation,
    // ArchivedContracts,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const contractsView = ref(null);

    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'contract-navigation';
      }

      window.scrollTo(0, contractsView.value.offsetTop);
    });

    const currTab = ref('contract-navigation');
    const setTab = async (tabName) => {
      currTab.value = tabName;

      router.replace({ query: { tab: tabName } });
    };

    return {
      currTab,
      setTab,
      'contracts-view': contractsView,
    };
  },
};
</script>
