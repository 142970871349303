<template>
  <div class="col-md-6 d-flex flex-column justify-content-between">
    <div v-if="entries.length > 0" class="row row-cols-1 g-2 lh-sm">
      <history-entry
        v-for="entry in entries"
        :key="entry.id"
        :entry="entry"
        :pro-id="proId"
        @is-shared-updated="$emit('entry-updated', $event)"
        @burn-trauma="$emit('burn-trauma', $event)"
        @delete-entry="$emit('delete-entry', $event)"
      />
    </div>
    <div v-else class="no-journal-enrties">
      <span class="text-muted"> No journal history entries</span>
    </div>
    <div v-if="entries.length > 0" class="row row-cols-1 g-2 lh-sm">
      <tbl-page-seln
        :model-value="pgNum"
        class="mt-3"
        :total-pages="totalPages"
        @update:modelValue="$emit('update:pgNum', $event)"
        @updated="$emit('page-updated')"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, inject, ref } from 'vue';
import { useStore } from 'vuex';
import HistoryEntry from './HistoryEntry.vue';
import TblPageSeln from '../../../shared/components/TblPageSeln.vue';

export default {
  components: {
    HistoryEntry,
    TblPageSeln,
  },
  props: {
    entries: {
      type: Array,
      required: true,
    },
    pgNum: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'update:pgNum',
    'page-updated',
    'entry-updated',
    'burn-trauma',
    'delete-entry',
  ],
  setup() {
    const patientApi = inject('api').patientApi();
    const store = useStore();

    const proId = ref(-1);
    onMounted(async () => {
      if (store.getters.userType == 'patient') {
        const response = await patientApi.getInfo();
        proId.value = response.data.profId;
      }
    });

    return {
      proId,
    };
  },
};
</script>
