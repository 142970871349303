<template>
  <div id="collapse-C" class="collapse show">
    <div ref="bucket-list-container" class="card-body position-relative">
      <div class="row">
        <div class="col">
          <h3>My Bucket List</h3>
          <!-- Add Bucket list item  -->
          <form class="form-floating mb-3" @submit.prevent="addItem">
            <div class="input-group">
              <div class="form-floating form-floating-group flex-grow-1">
                <input
                  id="new-bucket-input"
                  v-model="newItemText"
                  type="text"
                  class="form-control"
                  name="bucketList"
                  placeholder="Add Bucket List Item"
                />
                <label for="searchPatient">Enter Bucket List Item</label>
              </div>
              <button
                id="add-bucket-btn"
                :disabled="!newItemText"
                class="input-group-text btn-sm btn-primary px-3"
                type="submit"
              >
                Add
                <i class="fas fa-chevron-right ms-2"></i>
              </button>
            </div>
          </form>
          <div class="card mb-4">
            <div class="card-header pt-3">
              <div class="btn-toolbar">
                <!-- Search box -->
                <div
                  class="input-group input-group-sm flex-grow-1 mb-2 me-lg-2 me-xl-0 me-xxl-2"
                >
                  <input
                    id="search-bucket-input"
                    v-model.trim="filter"
                    type="search"
                    class="form-control"
                    placeholder="Search Bucket List"
                    @keyup.enter="srchActivities"
                  />
                  <button
                    id="search-buckets-btn"
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="srchActivities"
                  >
                    <i class="fas fa-search"></i>
                  </button>
                </div>
                <!-- Sort buttons -->
                <div
                  class="btn-group btn-group-sm flex-grow-1 mb-2"
                  role="group"
                >
                  <!-- <button
                    id="abc-sort-btn"
                    type="button"
                    class="btn btn-outline-secondary"
                    :class="{
                      active: sortField == 'text' && sortDirection == 'asc',
                    }"
                    @click="abcSort"
                  >
                    <i class="fas fa-sort-alpha-down"></i>
                  </button>
                  <button
                    id="zyx-sort-btn"
                    type="button"
                    class="btn btn-outline-secondary"
                    :class="{
                      active: sortField == 'text' && sortDirection == 'desc',
                    }"
                    @click="zyxSort"
                  >
                    <i class="fas fa-sort-alpha-down-alt"></i>
                  </button> -->
                  <button
                    id="completed-sort-btn"
                    type="button"
                    class="btn btn-outline-secondary"
                    :class="{
                      active:
                        sortField == 'completed' && sortDirection == 'desc',
                    }"
                    @click="completedSort"
                  >
                    <i class="fas fa-trophy"></i>
                  </button>
                  <button
                    id="incomplete-sort-btn"
                    type="button"
                    class="btn btn-outline-secondary"
                    :class="{
                      active:
                        sortField == 'completed' && sortDirection == 'asc',
                    }"
                    @click="incompleteSort"
                  >
                    <i class="far fa-square"></i>
                  </button>
                </div>
              </div>
            </div>
            <ul class="list-group list-group-flush mb-3">
              <li
                v-for="item in pagedBucketList"
                :key="item.id"
                class="list-group-item d-flex justify-content-between align-items-center pe-2"
              >
                <div v-if="currentlyEditingId != item.id">
                  <input
                    v-if="!item.completed"
                    id="complete-bucket-checkbox"
                    class="form-check-input me-1"
                    type="checkbox"
                    value=""
                    @change="completeItem(item)"
                  />
                  <i v-else class="fas fa-trophy me-2 text-gold"></i>
                  {{ item.text }}
                </div>
                <input
                  v-else
                  id="edited-bucket-input"
                  v-model="editedText"
                  class="form-control"
                  type="text"
                  @keyup.enter="confirmEdit"
                />
                <div
                  class="btn-group btn-group-sm"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    v-if="
                      currentlyEditingId != item.id && toBeDeleted != item.id
                    "
                    :id="`edit-btn-${item.id}`"
                    type="button"
                    class="btn btn-link"
                    :class="{ disabled: item.completed }"
                    @click="editItem(item)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    v-if="
                      currentlyEditingId != item.id && toBeDeleted != item.id
                    "
                    :id="`delete-btn-${item.id}`"
                    type="button"
                    class="btn btn-link link-danger"
                    @click="toBeDeleted = item.id"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <button
                    v-if="currentlyEditingId == item.id"
                    :id="`confirm-edit-btn-${item.id}`"
                    type="button"
                    class="btn btn-link link-success"
                    @click="confirmEdit"
                  >
                    <i class="fas fa-check-circle text-success"></i>
                  </button>
                  <button
                    v-if="currentlyEditingId == item.id"
                    :id="`cancel-edit-btn-${item.id}`"
                    type="button"
                    class="btn btn-link link-secondary"
                    @click="cancelEdit"
                  >
                    <i class="fas fa-times text-danger"></i>
                  </button>
                  <div
                    v-if="toBeDeleted == item.id"
                    class="btn-group btn-group-sm me-3 position-absolute top-50 end-0 translate-middle-y"
                    role="group"
                  >
                    <button
                      :id="`confirm-delete-btn-${item.id}`"
                      type="button"
                      class="btn btn-sm btn-danger"
                      @click="deleteItem(item)"
                    >
                      Delete
                    </button>
                    <button
                      :id="`cancel-delete-btn-${item.id}`"
                      type="button"
                      class="btn btn-sm btn-secondary ms-2"
                      @click="toBeDeleted = null"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <!-- Pagination -->
            <tbl-page-seln
              v-model="pageNum"
              :total-pages="totalPages"
              @updated="refresh()"
            ></tbl-page-seln>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import {
  getLodashArray,
  getPage,
} from '../../.././../services/lib/clientPaging';
import TblPageSeln from '../../shared/components/TblPageSeln.vue';
var _ = require('lodash');

export default {
  components: {
    TblPageSeln,
  },
  emits: ['update:currentlyEditingId'],
  setup() {
    const lifeplanApi = inject('api').lifeplanApi();
    const loading = inject('$loading');

    const bucketListContainer = ref(null);

    onMounted(() => {
      refresh();
    });

    const pagedBucketList = ref([]);
    const pageNum = ref(1); // 1 = first page
    const pageSize = ref(5); // default to five items per page
    const filter = ref(''); // no filter initially
    const sortField = ref('text'); // this is the field to sort on
    const sortDirection = ref('asc'); // sort diection 'asc'ending or 'desc'ending

    const totalPages = ref(0);

    const refresh = async (bucketList) => {
      const loader = loading.show({
        container: bucketListContainer.value,
        isFullPage: false,
      });
      if (!bucketList) {
        const response = await lifeplanApi.getBucketList();
        bucketList = response.data;
      }

      // console.log(bucketList);
      const a = _.orderBy(
        bucketList,
        [(o) => o['text'].toLowerCase()],
        ['asc']
      );
      console.log(a);

      const sortingProperty = (i) => {
        if (sortField.value == 'text') return i[sortField.value].toLowerCase();
        return i[sortField.value];
      };

      const result = getPage(
        getLodashArray(bucketList),
        pageNum.value, // page to display
        pageSize.value,
        (i) =>
          !filter.value ||
          i.text.toLowerCase().includes(filter.value.toLowerCase()), // filter by bucket item text
        sortingProperty, // sorting property
        sortDirection.value // sorting direction
      );

      pagedBucketList.value = result.data;
      totalPages.value = result.totalPages;

      // if (filter.value && pagedBucketList.value.length < pageSize.value) {
      //   disableNext.value = true;
      // }

      loader.hide();
    };

    const newItemText = ref('');

    const addItem = async () => {
      if (!newItemText.value) return;
      const loader = loading.show({
        container: bucketListContainer.value,
        isFullPage: false,
      });
      const response = await lifeplanApi.addBucketListItem({
        text: newItemText.value,
      });
      newItemText.value = '';
      loader.hide();
      refresh(response.data);
    };

    const editedText = ref('');

    const editItem = (bucketItem) => {
      editedText.value = bucketItem.text;
      currentlyEditingId.value = bucketItem.id;
    };

    const confirmEdit = async () => {
      const loader = loading.show({
        container: bucketListContainer.value,
        isFullPage: false,
      });
      const bucketItem = pagedBucketList.value.find(
        (item) => item.id == currentlyEditingId.value
      );
      const response = await lifeplanApi.updateBucketListItem({
        id: bucketItem.id,
        text: editedText.value,
        completed: bucketItem.completed,
      });
      currentlyEditingId.value = bucketItem.id = -1;
      editedText.value = '';
      refresh(response.data);
      loader.hide();
    };

    const cancelEdit = () => {
      currentlyEditingId.value = -1;
      editedText.value = '';
    };

    const completeItem = async (bucketItem) => {
      const loader = loading.show({
        container: bucketListContainer.value,
        isFullPage: false,
      });
      const response = await lifeplanApi.updateBucketListItem({
        id: bucketItem.id,
        text: bucketItem.text,
        completed: true,
      });
      refresh(response.data);
      loader.hide();
    };

    const toBeDeleted = ref(null);

    const deleteItem = async (bucketItem) => {
      const loader = loading.show({
        container: bucketListContainer.value,
        isFullPage: false,
      });
      const response = await lifeplanApi.removeBucketListItem(bucketItem);
      refresh(response.data);
      loader.hide();
    };

    const abcSort = () => {
      sortField.value = 'text';
      sortDirection.value = 'asc';
      refresh();
    };

    const zyxSort = () => {
      sortField.value = 'text';
      sortDirection.value = 'desc';
      refresh();
    };
    const completedSort = () => {
      sortField.value = 'completed';
      sortDirection.value = 'desc';
      refresh();
    };
    const incompleteSort = () => {
      sortField.value = 'completed';
      sortDirection.value = 'asc';
      refresh();
    };

    const srchActivities = () => {
      pageNum.value = 1;
      refresh();
    };

    const currentlyEditingId = ref(-1);

    // call refresh & listen for attempts to leave app (refresh, close window/tab, navigate to different sites, etc.)
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // check for unsaved changes before leaving app

    const preventNav = (event) => {
      if (currentlyEditingId.value == -1) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    // checks for unsaved changes before intra-app routing
    const store = useStore();
    onBeforeRouteLeave((to, from, next) => {
      if (currentlyEditingId.value >= 0 && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      next();
    });

    return {
      'bucket-list-container': bucketListContainer,
      newItemText,
      addItem,
      editItem,
      confirmEdit,
      cancelEdit,
      completeItem,
      toBeDeleted,
      deleteItem,
      pagedBucketList,
      pageNum,
      totalPages,
      editedText,
      sortField,
      sortDirection,
      abcSort,
      zyxSort,
      completedSort,
      incompleteSort,
      filter,
      refresh,
      srchActivities,
      currentlyEditingId,
    };
  },
};
</script>
