<template>
  <div id="burnModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content position-relative bg-transparent">
        <div
          class="modal-body position-absolute top-50 start-50 translate-middle"
        >
          <div class="fire">
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
            <div class="particle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.fire {
  font-size: 32px;
  filter: blur(0.02em);
  -webkit-filter: blur(0.02em);
  margin: 3em auto 0 auto;
  position: relative;
  width: 10em;
  height: 12em;
}

.particle {
  animation: rise 1s ease-in infinite;
  background-image: radial-gradient(#ff5000 20%, rgba(255, 80, 0, 0) 70%);
  border-radius: 50%;
  mix-blend-mode: screen;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 5em;
  height: 5em;
}
.particle:nth-of-type(1) {
  animation-delay: 0.6294090202s;
  left: calc((100% - 5em) * 0);
}
.particle:nth-of-type(2) {
  animation-delay: 0.055890985s;
  left: calc((100% - 5em) * 0.02);
}
.particle:nth-of-type(3) {
  animation-delay: 0.1889092829s;
  left: calc((100% - 5em) * 0.04);
}
.particle:nth-of-type(4) {
  animation-delay: 0.2171050924s;
  left: calc((100% - 5em) * 0.06);
}
.particle:nth-of-type(5) {
  animation-delay: 0.7853033121s;
  left: calc((100% - 5em) * 0.08);
}
.particle:nth-of-type(6) {
  animation-delay: 0.6738382423s;
  left: calc((100% - 5em) * 0.1);
}
.particle:nth-of-type(7) {
  animation-delay: 0.1954000233s;
  left: calc((100% - 5em) * 0.12);
}
.particle:nth-of-type(8) {
  animation-delay: 0.4829681188s;
  left: calc((100% - 5em) * 0.14);
}
.particle:nth-of-type(9) {
  animation-delay: 0.5938402117s;
  left: calc((100% - 5em) * 0.16);
}
.particle:nth-of-type(10) {
  animation-delay: 0.5732908573s;
  left: calc((100% - 5em) * 0.18);
}
.particle:nth-of-type(11) {
  animation-delay: 0.7656887792s;
  left: calc((100% - 5em) * 0.2);
}
.particle:nth-of-type(12) {
  animation-delay: 0.2064406564s;
  left: calc((100% - 5em) * 0.22);
}
.particle:nth-of-type(13) {
  animation-delay: 0.7250203664s;
  left: calc((100% - 5em) * 0.24);
}
.particle:nth-of-type(14) {
  animation-delay: 0.0572674797s;
  left: calc((100% - 5em) * 0.26);
}
.particle:nth-of-type(15) {
  animation-delay: 0.1985615694s;
  left: calc((100% - 5em) * 0.28);
}
.particle:nth-of-type(16) {
  animation-delay: 0.1844614282s;
  left: calc((100% - 5em) * 0.3);
}
.particle:nth-of-type(17) {
  animation-delay: 0.0475244136s;
  left: calc((100% - 5em) * 0.32);
}
.particle:nth-of-type(18) {
  animation-delay: 0.7012200839s;
  left: calc((100% - 5em) * 0.34);
}
.particle:nth-of-type(19) {
  animation-delay: 0.838255623s;
  left: calc((100% - 5em) * 0.36);
}
.particle:nth-of-type(20) {
  animation-delay: 0.9131353642s;
  left: calc((100% - 5em) * 0.38);
}
.particle:nth-of-type(21) {
  animation-delay: 0.4660077982s;
  left: calc((100% - 5em) * 0.4);
}
.particle:nth-of-type(22) {
  animation-delay: 0.6544091121s;
  left: calc((100% - 5em) * 0.42);
}
.particle:nth-of-type(23) {
  animation-delay: 0.3758189705s;
  left: calc((100% - 5em) * 0.44);
}
.particle:nth-of-type(24) {
  animation-delay: 0.9296969611s;
  left: calc((100% - 5em) * 0.46);
}
.particle:nth-of-type(25) {
  animation-delay: 0.3042616443s;
  left: calc((100% - 5em) * 0.48);
}
.particle:nth-of-type(26) {
  animation-delay: 0.1746305763s;
  left: calc((100% - 5em) * 0.5);
}
.particle:nth-of-type(27) {
  animation-delay: 0.2869209008s;
  left: calc((100% - 5em) * 0.52);
}
.particle:nth-of-type(28) {
  animation-delay: 0.5435305148s;
  left: calc((100% - 5em) * 0.54);
}
.particle:nth-of-type(29) {
  animation-delay: 0.6835994363s;
  left: calc((100% - 5em) * 0.56);
}
.particle:nth-of-type(30) {
  animation-delay: 0.7279872556s;
  left: calc((100% - 5em) * 0.58);
}
.particle:nth-of-type(31) {
  animation-delay: 0.8130213407s;
  left: calc((100% - 5em) * 0.6);
}
.particle:nth-of-type(32) {
  animation-delay: 0.4418465709s;
  left: calc((100% - 5em) * 0.62);
}
.particle:nth-of-type(33) {
  animation-delay: 0.2499113003s;
  left: calc((100% - 5em) * 0.64);
}
.particle:nth-of-type(34) {
  animation-delay: 0.6985789383s;
  left: calc((100% - 5em) * 0.66);
}
.particle:nth-of-type(35) {
  animation-delay: 0.0400846415s;
  left: calc((100% - 5em) * 0.68);
}
.particle:nth-of-type(36) {
  animation-delay: 0.2263542069s;
  left: calc((100% - 5em) * 0.7);
}
.particle:nth-of-type(37) {
  animation-delay: 0.479808071s;
  left: calc((100% - 5em) * 0.72);
}
.particle:nth-of-type(38) {
  animation-delay: 0.8036392313s;
  left: calc((100% - 5em) * 0.74);
}
.particle:nth-of-type(39) {
  animation-delay: 0.1974468933s;
  left: calc((100% - 5em) * 0.76);
}
.particle:nth-of-type(40) {
  animation-delay: 0.0646670455s;
  left: calc((100% - 5em) * 0.78);
}
.particle:nth-of-type(41) {
  animation-delay: 0.2341672667s;
  left: calc((100% - 5em) * 0.8);
}
.particle:nth-of-type(42) {
  animation-delay: 0.6162363491s;
  left: calc((100% - 5em) * 0.82);
}
.particle:nth-of-type(43) {
  animation-delay: 0.8095513403s;
  left: calc((100% - 5em) * 0.84);
}
.particle:nth-of-type(44) {
  animation-delay: 0.9557705932s;
  left: calc((100% - 5em) * 0.86);
}
.particle:nth-of-type(45) {
  animation-delay: 0.6759558603s;
  left: calc((100% - 5em) * 0.88);
}
.particle:nth-of-type(46) {
  animation-delay: 0.722143565s;
  left: calc((100% - 5em) * 0.9);
}
.particle:nth-of-type(47) {
  animation-delay: 0.2106007571s;
  left: calc((100% - 5em) * 0.92);
}
.particle:nth-of-type(48) {
  animation-delay: 0.4716410613s;
  left: calc((100% - 5em) * 0.94);
}
.particle:nth-of-type(49) {
  animation-delay: 0.6176448939s;
  left: calc((100% - 5em) * 0.96);
}
.particle:nth-of-type(50) {
  animation-delay: 0.7297322489s;
  left: calc((100% - 5em) * 0.98);
}

@keyframes rise {
  from {
    opacity: 0;
    transform: translateY(0) scale(1);
  }
  25% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10em) scale(0);
  }
}
</style>
