<template>
  <!-- <div class="col mt-1 mb-2">
    <a class="btn btn-secondary" href="https://bhealthy.cloudu.com/Course/Start?courseID=74&lessonId=307" >Learn More</a>
  </div> -->
  <div ref="life-plan-view" class="col responsive-tabs">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          id="life-plan-summary"
          class="nav-link"
          :class="{ active: currTab == 'life-plan-summary' }"
          style="cursor: pointer"
          @click="setTab('life-plan-summary')"
          >Summary</a
        >
      </li>
      <li class="nav-item">
        <a
          id="life-plan-dream-actions"
          class="nav-link"
          :class="{ active: currTab == 'dreams-actions' }"
          style="cursor: pointer"
          @click="setTab('dreams-actions')"
          >My Dreams &amp; Plans</a
        >
      </li>
      <li class="nav-item">
        <a
          id="life-plan-bucket-list"
          class="nav-link"
          :class="{ active: currTab == 'bucket-list' }"
          style="cursor: pointer"
          @click="setTab('bucket-list')"
          >Bucket List</a
        >
      </li>
    </ul>

    <!-- Accordion items - headers hidden on desktop view-->
    <div id="content" class="tab-content">
      <!-- tab one -->
      <div
        id="pane-A"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'life-plan-summary' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              id="life-plan-summary-mobile"
              :class="{ collapsed: currTab != 'life-plan-summary' }"
              style="cursor: pointer"
              @click="setTab('life-plan-summary')"
              >Summary</a
            >
          </h5>
        </div>
        <life-plan-summary v-if="currTab == 'life-plan-summary'" />
      </div>
      <!-- tab two -->
      <div
        id="pane-B"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'dreams-actions' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              id="life-plan-dreams-actions-mobile"
              :class="{ collapsed: currTab != 'dreams-actions' }"
              style="cursor: pointer"
              @click="setTab('dreams-actions')"
              >My Dreams &amp; Plans</a
            >
          </h5>
        </div>
        <dreams-actions
          v-if="currTab == 'dreams-actions'"
          :is-dirty="isDirty"
          @dirty="isDirty = true"
          @clean="isDirty = false"
        />
      </div>
      <!-- tab three -->
      <div
        id="pane-C"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'bucket-list' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              id="life-plan-bucket-list-mobile"
              :class="{ collapsed: currTab != 'bucket-list' }"
              style="cursor: pointer"
              @click="setTab('bucket-list')"
              >Bucket List</a
            >
          </h5>
        </div>
        <bucket-list v-if="currTab == 'bucket-list'" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, onBeforeMount, onBeforeUnmount, watch } from 'vue';
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import LifePlanSummary from './LifePlanSummary.vue';
import DreamsActions from './dreams-actions/DreamsActions.vue';
import BucketList from './BucketList.vue';

export default {
  components: {
    LifePlanSummary,
    DreamsActions,
    BucketList,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const lifePlanView = ref(null);

    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'life-plan-summary';
      }

      window.scrollTo(0, lifePlanView.value.offsetTop);
    });

    watch(route, () => {
      currTab.value = route.query.tab;
    });

    const currTab = ref('life-plan-summary');
    const setTab = async (tabName) => {
      if (isDirty.value) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;

      currTab.value = tabName;

      router.replace({ query: { tab: tabName } });
    };

    const isDirty = ref(false);

    // listen for attempts to leave app
    onBeforeMount(() => {
      window.addEventListener('beforeunload', preventNav);
    });

    // prevent memory leak
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', preventNav);
    });

    // checks for unsaved changes before leaving app
    const preventNav = (event) => {
      if (!isDirty.value) return;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    const store = useStore();
    // checks for unsaved changes before intra-app routing
    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value && store.getters.isAuthed) {
        if (
          !window.confirm(
            'Are you sure you want to leave? You have unsaved changes.'
          )
        ) {
          return;
        }
      }
      isDirty.value = false;
      next();
    });

    return {
      currTab,
      setTab,
      isDirty,
      'life-plan-view': lifePlanView,
    };
  },
};
</script>
