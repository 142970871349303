<template>
  <!-- <div class="col mt-1 mb-2">
    <a class="btn btn-secondary" href="https://bhealthy.cloudu.com/Course/Start?courseID=73&lessonId=291" >Learn More</a>
  </div> -->
  <div class="d-flex container-fluid  justify-content-center align-items-center">
  <div ref="journal-view" class="col responsive-tabs">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'journal-today' }"
          style="cursor: pointer"
          @click="setTab('journal-today')"
          >Today</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: currTab == 'journal-history' }"
          style="cursor: pointer"
          @click="setTab('journal-history')"
          >History</a
        >
      </li>
    </ul>

    <!-- Accordion items - headers hidden on desktop view-->
    <div id="content" class="tab-content mb-4">
      <!-- tab one -->
      <div
        id="pane-A"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'journal-today' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'journal-today' }"
              style="cursor: pointer"
              @click="setTab('journal-today')"
              >Today</a
            >
          </h5>
        </div>
        <journal-today v-if="currTab == 'journal-today'" />
      </div>
      <!-- tab two -->
      <div
        id="pane-B"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'journal-history' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              :class="{ collapsed: currTab != 'journal-history' }"
              style="cursor: pointer"
              @click="setTab('journal-history')"
              >History</a
            >
          </h5>
        </div>
        <journal-history v-if="currTab == 'journal-history'" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import JournalToday from './journal-today/JournalToday.vue';
import JournalHistory from './journal-history/JournalHistory.vue';

export default {
  components: {
    JournalToday,
    JournalHistory,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const journalView = ref(null);

    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'journal-today';
      }

      window.scrollTo(0, journalView.value.offsetTop);
    });

    const currTab = ref('journal-today');
    const setTab = async (tabName) => {
      currTab.value = tabName;

      const query = { tab: tabName }
      if (route.query.myCare) {
        query.myCare = route.query.myCare
      }

      router.replace({ query: query });
    };

    return {
      currTab,
      setTab,
      'journal-view': journalView,
    };
  },
};
</script>
