<template>
  <base-modal ref="modal" :show-btn-classes="{ 'd-none': true }">
    <template #header-title>Choose Your Signature</template>
    <template #body>
      <div class="d-grid gap-3 d-md-flex justify-content-md-around">
        <button
          id="font1-btn"
          class="btn btn-outline-secondary border p-3 flex-grow-1"
          :class="getCardClasses('Alex Brush')"
          type="button"
          style="font-family: 'Alex Brush'"
          @click="selectFont('Alex Brush')"
        >
          {{ fullName }}
        </button>
        <button
          id="font2-btn"
          class="btn btn-outline-secondary border p-3 flex-grow-1"
          :class="getCardClasses('Bonheur Royale')"
          type="button"
          style="font-family: 'Bonheur Royale'"
          @click="selectFont('Bonheur Royale')"
        >
          {{ fullName }}
        </button>
        <button
          id="font3-btn"
          class="btn btn-outline-secondary border p-3 flex-grow-1"
          :class="getCardClasses('Corinthia')"
          type="button"
          style="font-family: 'Corinthia'"
          @click="selectFont('Corinthia')"
        >
          {{ fullName }}
        </button>
      </div>
    </template>
    <template #footer="footerProps">
      <button
        id="confirm-sign-btn"
        type="button"
        class="btn btn-primary"
        @click="signContract"
      >
        Sign
      </button>
      <button
        id="cancel-sign-btn"
        type="button"
        class="btn btn-secondary"
        @click="footerProps.toggleModal"
      >
        Cancel
      </button>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '../../../shared/UI/BaseModal';
import { ref } from 'vue';

export default {
  components: {
    BaseModal,
  },
  props: {
    fullName: {
      type: String,
      required: true,
    },
  },
  emits: ['signed'],
  setup(props, context) {
    // call base-modal's toggleModal function
    const modal = ref(null);
    const toggleSigModal = () => {
      modal.value.toggleModal();
    };

    // select font
    const selectedFont = ref('Alex Brush');
    const selectFont = (font) => {
      selectedFont.value = font;
    };

    // update card classes to reflect current font selection
    const getCardClasses = (font) => {
      return {
        active: font == selectedFont.value,
      };
    };

    // sign contract
    const signContract = () => {
      let fontClass = '';
      switch (selectedFont.value) {
        case 'Alex Brush':
          fontClass = 'alex-brush-font';
          break;
        case 'Bonheur Royale':
          fontClass = 'bonheur-royale-font';
          break;
        case 'Corinthia':
          fontClass = 'corinthia-font';
          break;
      }
      context.emit('signed', fontClass);
    };

    return {
      modal,
      toggleSigModal,
      selectFont,
      getCardClasses,
      signContract,
      selectedFont,
    };
  },
};
</script>

<style>
/* alex-brush-regular - latin */
@font-face {
  font-family: 'Alex Brush';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../assets/fonts/alex-brush-v13-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../assets/fonts/alex-brush-v13-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* bonheur-royale-regular - latin */
@font-face {
  font-family: 'Bonheur Royale';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../assets/fonts/bonheur-royale-v7-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../assets/fonts/bonheur-royale-v7-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* corinthia-regular - latin */
@font-face {
  font-family: 'Corinthia';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../assets/fonts/corinthia-v7-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../assets/fonts/corinthia-v7-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.alex-brush-font {
  font-family: Alex Brush;
}

.bonheur-royale-font {
  font-family: Bonheur Royale;
}

.corinthia-font {
  font-family: Corinthia;
}
</style>
