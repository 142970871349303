<template>
  <!-- <div class="col mt-1 mb-2">
    <a class="btn btn-secondary" href="https://bhealthy.cloudu.com/Course/Start?courseID=75&lessonId=290" >Learn More</a>
  </div> -->
  <div ref="diversions-view" class="col responsive-tabs">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          id="add-diversion-tab"
          class="nav-link"
          :class="{ active: currTab == 'add-diversion' }"
          style="cursor: pointer"
          @click="setTab('add-diversion')"
          >Coping Skill</a
        >
      </li>
      <li class="nav-item">
        <a
          id="manage-diversions-tab"
          class="nav-link"
          :class="{ active: currTab == 'manage-diversions' }"
          style="cursor: pointer"
          @click="setTab('manage-diversions')"
          >Manage Coping Skills</a
        >
      </li>
      <li class="nav-item">
        <a
          id="diversion-history-tab"
          class="nav-link"
          :class="{ active: currTab == 'diversion-history' }"
          style="cursor: pointer"
          @click="setTab('diversion-history')"
          >Coping Skill History</a
        >
      </li>
    </ul>

    <!-- Accordion items - headers hidden on desktop view-->
    <div id="content" class="tab-content">
      <!-- tab one -->
      <div
        id="pane-A"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'add-diversion' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              id="add-diversion-tab-mobile"
              :class="{ collapsed: currTab != 'add-diversion' }"
              style="cursor: pointer"
              @click="setTab('add-diversion')"
              >Diversion</a
            >
          </h5>
        </div>
        <add-diversion v-if="currTab == 'add-diversion'" />
      </div>
      <!-- tab two -->
      <div
        id="pane-B"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'manage-diversions' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              id="manage-diversions-tab-mobile"
              :class="{ collapsed: currTab != 'manage-diversions' }"
              style="cursor: pointer"
              @click="setTab('manage-diversions')"
              >Manage Diversions</a
            >
          </h5>
        </div>
        <manage-diversions v-if="currTab == 'manage-diversions'" />
      </div>
      <!-- tab three -->
      <div
        id="pane-C"
        class="card tab-pane fade"
        :class="{ 'active show': currTab == 'diversion-history' }"
      >
        <div class="card-header">
          <h5 class="mb-0">
            <a
              id="diversion-history-tab-mobile"
              :class="{ collapsed: currTab != 'diversion-history' }"
              style="cursor: pointer"
              @click="setTab('diversion-history')"
              >Diversion History</a
            >
          </h5>
        </div>
        <diversion-history v-if="currTab == 'diversion-history'" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import AddDiversion from './AddDiversion.vue';
import ManageDiversions from './ManageDiversions.vue';
import DiversionHistory from './DiversionHistory.vue';

export default {
  components: {
    AddDiversion,
    ManageDiversions,
    DiversionHistory,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const diversionsView = ref(null);

    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'add-diversion';
      }
      window.scrollTo(0, diversionsView.value.offsetTop);
      console.log(diversionsView.value.offsetTop);
    });

    const currTab = ref('my-patients');
    const setTab = async (tabName) => {
      currTab.value = tabName;

      router.replace({ query: { tab: tabName } });
    };

    return {
      currTab,
      setTab,
      'diversions-view': diversionsView,
    };
  },
};
</script>
