<template>
  <div id="collapse-C" class="collapse show">
    <div class="card-body">
      <h5>Past Coping Skills</h5>
    </div>
    <div class="row">
      <div ref="diversion-container" class="col mb-3">
        <!-- <ol>
          <li v-for="item in diversions" :key="item.id">
            {{ item.title }}
            <span v-if="item.completeDt" class="small text-muted ms-4">
              [ {{ item.completeDt }} ]
            </span>
          </li>
        </ol> -->
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Completion Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in diversions" :key="item.id">
              <td>{{ item.title }}</td>
              <td class="text-muted">{{ item.completeDt ? item.completeDt : "Not Completed" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Pagination -->
    <tbl-page-seln v-model="pgNum" :total-pages="totalPages" @updated="refresh" />
  </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import TblPageSeln from '../../shared/components/TblPageSeln.vue';
import stdLocalTime from '../../../../services/lib/localTime';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export default {
  components: {
    TblPageSeln,
  },
  setup() {
    dayjs.extend(utc);
    const api = inject('api').patientApi();
    const loading = inject('$loading');

    const DiversionContainer = ref(null);
    const diversions = ref([]);
    const pgNum = ref(1);
    const pgSz = ref(10);
    const totalPages = ref(0);

    const refresh = async () => {
      const loader = loading.show({
        container: DiversionContainer.value,
        isFullPage: false,
      });

      const response = await api.getDiversionHistory(
        pgSz.value,
        pgNum.value - 1
      );
      totalPages.value = response.data.totalPages;
      response.data.data.forEach((e) => {
        if (e.completedOnUtc) {
          e.completeDt = stdLocalTime(e.completedOnUtc, true);
        }
      });
      diversions.value = response.data.data;

      loader.hide();
    };

    onMounted(async () => {
      await refresh();
    });

    return {
      'diversion-container': DiversionContainer,
      refresh,
      diversions,
      pgNum,
      totalPages,
    };
  },
};
</script>
