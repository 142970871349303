<template>
  <h2>My Dreams</h2>
  <div class="form-floating mb-3">
    <textarea
      id="million-dollar-input"
      v-model="localLifePlan.millionDollarQuestion"
      class="form-control"
      placeholder="Million Dollar Question"
      style="height: 100px"
      @input="$emit('dirty')"
    ></textarea>
    <label for="floatingTextarea2">Million Dollar Question</label>
  </div>
  <div class="form-floating mb-3">
    <textarea
      id="retirement-input"
      v-model="localLifePlan.retirement"
      class="form-control"
      placeholder="Where do you want to Retire"
      style="height: 100px"
      @input="$emit('dirty')"
    ></textarea>
    <label for="floatingTextarea2">Where Do You Want To Retire? </label>
  </div>
</template>

<script>
import { computed } from 'vue';
export default {
  props: {
    lifePlan: {
      type: Object,
      required: true,
    },
  },
  emits: ['dirty', 'update:lifePlan'],
  setup(props, context) {
    const localLifePlan = computed({
      get: () => props.lifePlan,
      set: (value) => context.emit('update:lifePlan', value),
    });

    return { localLifePlan };
  },
};
</script>
