<template>
  <form
    ref="create-diversion"
    class="form-floating p-3"
    @submit.prevent="add_activity"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-floating mb-3">
          <input
            id="diversionDescription"
            v-model="divDescription"
            type="text"
            class="form-control"
            placeholder="Diversion description"
          />
          <label for="floatingInput">Coping Skill Name</label>
        </div>
      </div>
      <!-- <div class="col-12">
        <div class="form-floating mb-3">
          <input
            id="diversionDescription"
            v-model="description"
            type="text"
            class="form-control"
            placeholder="Diversion description"
          />
          <label for="floatingInput">Description (optional)</label>
        </div>
      </div>
      <div class="col-12">
        <div class="form-floating mb-3">
          <input
            id="diversionDescription"
            v-model="link"
            type="text"
            class="form-control"
            placeholder="Diversion description"
          />
          <label for="floatingInput">Link (optional)</label>
        </div>
      </div> -->
      <div class="col-12 text-end">
        <button
          id="save-activity-btn"
          :disabled="!divDescription"
          type="submit"
          class="btn btn-primary"
        >
          Save Coping Skill
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, ref } from 'vue';

export default {
  emits: ['add-diversion'],
  setup(props, context) {
    const userApi = inject('api').patientApi();
    const divDescription = ref('');

    const add_activity = async () => {
      await userApi.addDiversion({ text: divDescription.value });
      divDescription.value = '';
      context.emit('add-diversion');
    };

    const description = ref('')
    const link = ref('')

    return {
      divDescription,
      add_activity,
      description,
      link
    };
  },
};
</script>