<template>
  <div id="collapse-B" class="collapse show">
    <div class="card-body">
      <div class="row">
        <!-- Activities Creator -->
        <div class="col-md-6 mb-4">
          <h2>Create Coping Skill</h2>
          <create-diversion @add-diversion="refresh()" />
        </div>
        <div class="col mb-4">
          <h2>My Coping Skills</h2>
          <div ref="diversion-container" class="card mb-4 position-relative">
            <div class="card-header pt-3">
              <div class="btn-toolbar">
                <form @submit.prevent="srchItems()">
                  <div
                    class="input-group input-group-sm flex-grow-1 mb-2 me-lg-2 me-xl-0 me-xxl-2"
                  >
                    <input
                      id="diversions-filter"
                      v-model="filter"
                      type="text"
                      class="form-control"
                      placeholder="Search Coping Skills"
                    />
                    <button
                      id="searchDiversions"
                      class="btn btn-outline-secondary"
                      type="submit"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </form>

                <div
                  class="btn-group btn-group-sm flex-grow-1 mb-2"
                  role="group"
                >
                  <!-- <button
                    id="abc-sort"
                    type="button"
                    class="btn btn-outline-secondary"
                    :class="{
                      active: sortField === 'title' && sortDir === 'asc',
                    }"
                    @click="sortTextAsc()"
                  >
                    <i class="fas fa-sort-alpha-down"></i>
                  </button>
                  <button
                    id="zyx-sort"
                    type="button"
                    class="btn btn-outline-secondary"
                    :class="{
                      active: sortField === 'title' && sortDir === 'desc',
                    }"
                    @click="sortTextDesc()"
                  >
                    <i class="fas fa-sort-alpha-down-alt"></i>
                  </button> -->
                  <button
                    id="datetime-sort"
                    type="button"
                    class="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                    :class="{ active: sortField === 'ts' }"
                    @click="sortTimestamp()"
                  >
                    <p style="margin-right: 1rem;" class="mr-2 mb-0 ">Sort By Datetime</p><i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
            </div>

            <ul class="list-group list-group-flush mb-3">
              <li
                v-for="d in diversions"
                :key="d.id"
                class="list-group-item d-flex justify-content-between align-items-center pe-2"
              >
                <div>
                  {{ d.title }}
                </div>
                <button
                  v-if="toBeDeleted != d.id"
                  :id="`delete-btn-${d.id}`"
                  class="btn btn-sm btn-link link-danger"
                  @click="toBeDeleted = d.id"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
                <div
                  v-else
                  class="btn-group btn-group-sm me-3 position-absolute top-50 end-0 translate-middle-y"
                  role="group"
                >
                  <button
                    :id="`confirm-delete-btn-${d.id}`"
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="doDelete(d.id)"
                  >
                    Delete
                  </button>
                  <button
                    :id="`cancel-delete-btn-${d.id}`"
                    type="button"
                    class="btn btn-sm btn-secondary ms-2"
                    @click="toBeDeleted = null"
                  >
                    Cancel
                  </button>
                </div>
              </li>
            </ul>
            <!-- Pagination -->
            <tbl-page-seln
              v-model="pageNum"
              :total-pages="totalPages"
              @updated="refresh()"
            ></tbl-page-seln>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref } from 'vue';
import CreateDiversion from './CreateDiversion.vue';
import TblPageSeln from '../../shared/components/TblPageSeln.vue';
import {
  getLodashArray,
  alterArrayItems,
  getPage,
} from '../../.././../services/lib/clientPaging';

export default {
  components: {
    CreateDiversion,
    TblPageSeln,
  },
  setup() {
    const api = inject('api').patientApi();
    const userApi = inject('api').userApi();
    const loading = inject('$loading');

    const DiversionContainer = ref(null);
    const diversions = ref([]);
    const filter = ref('');
    const pageNum = ref(1);
    const pageSize = ref(5);
    const sortField = ref('ts');
    const sortDir = ref('desc');
    const toBeDeleted = ref(null);
    const totalPages = ref(0);

    const refresh = async () => {
      const loader = loading.show({
        container: DiversionContainer.value,
        isFullPage: false,
      });

      const response = await userApi.getDiversions();

      const mapped = alterArrayItems(
        getLodashArray(response.data),
        function (i) {
          return { ...i, ts: new Date(i.addedOnUtc).getTime() };
        }
      );

      const sorter =
        sortField.value === 'ts'
          ? 'ts'
          : (o) => o[sortField.value].toLowerCase();

      const result = getPage(
        mapped, // items include a generated 'ts' property to sort on
        pageNum.value, // page to display
        pageSize.value,
        (i) =>
          !filter.value ||
          i.title.toLowerCase().includes(filter.value.toLowerCase()),
        sorter,
        sortDir.value
      );

      diversions.value = result.data;
      totalPages.value = result.totalPages;

      // if (filter.value && diversions.value.length < pageSize.value) {
      //   disableNext.value = true;
      // }

      loader.hide();
    };

    onMounted(async () => {
      await refresh();
    });

    const srchItems = async () => {
      pageNum.value = 1;
      await refresh();
    };
    const sortTimestamp = async () => {
      sortField.value = 'ts';
      sortDir.value = 'desc';
      await refresh();
    };
    const sortTextAsc = async () => {
      sortField.value = 'title';
      sortDir.value = 'asc';
      await refresh();
    };
    const sortTextDesc = async () => {
      sortField.value = 'title';
      sortDir.value = 'desc';
      await refresh();
    };

    const doDelete = async (id) => {
      toBeDeleted.value = null;
      await api.remDiversion(id);
      await refresh();
    };

    return {
      'diversion-container': DiversionContainer,
      refresh,
      doDelete,
      diversions,
      filter,
      pageNum,
      sortField,
      sortDir,
      toBeDeleted,
      srchItems,
      sortTimestamp,
      sortTextAsc,
      sortTextDesc,
      totalPages,
    };
  },
};
</script>
