<template>
  <quill-editor
    v-if="!isSigned"
    id="quill-textarea"
    ref="quill-contract-editor"
    :content="localCurrContract[contractField]"
    :style="[`height: ${height}`]"
    theme="snow"
    toolbar="essential"
    :options="contractOptions"
    content-type="html"
    @update:content="updateInput"
  ></quill-editor>

  <!-- eslint-disable vue/no-v-html -->
  <div
    v-if="isSigned"
    class="form-control bg-light text-muted ql-editor"
    style="min-height: 25vh"
    v-html="localCurrContract.contract"
  ></div>
  <!-- eslint-enable -->
</template>

<script>
import { ref, watch, toRef, computed, nextTick } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';

export default {
  components: {
    QuillEditor,
  },
  props: {
    currContract: {
      type: Object,
      required: true,
    },
    containerId: {
      type: String,
      default: '',
    },
    isSigned: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      required: true,
    },
    contractField: {
      type: String,
      default: 'contract',
    },
  },
  emits: ['update:currContract', 'dirty'],
  setup(props, context) {
    const localCurrContract = computed({
      get: () => props.currContract,
      set: (value) => context.emit('update:currContract', value),
    });

    // manually update contract editor's content anytime personal_contract gets reassigned (temporary fix for vue-quill reactivity bug)
    const quillContractEditor = ref(null);
    watch(toRef(props, 'contractId'), () => {
      nextTick(() => {
        if (quillContractEditor.value) {
          quillContractEditor.value.setHTML(
            localCurrContract.value[props.contractField]
          );
        }
      });
    });

    // update data to reflect user input
    const updateInput = (newValue) => {
      if (quillContractEditor.value) {
        // check for a real difference to avoid inappropriately setting isDirty to true (quill editor emits a superfluous update:content event when it's first given data)
        if (
          newValue != localCurrContract.value[props.contractField] &&
          !(
            newValue == '' &&
            localCurrContract.value[props.contractField] == '<p><br></p>'
          ) &&
          !(
            newValue == '<p><br></p>' &&
            localCurrContract.value[props.contractField] == ''
          )
        ) {
          context.emit('dirty');
          localCurrContract.value[props.contractField] = newValue;
        }
      }
    };

    // configure quill editor
    const contractOptions = {
      bounds: `#${props.containerId}`,
    };

    return {
      localCurrContract,
      'quill-contract-editor': quillContractEditor,
      updateInput,
      contractOptions,
    };
  },
};
</script>

<style>
/* alex-brush-regular - latin */
@font-face {
  font-family: 'Alex Brush';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../assets/fonts/alex-brush-v13-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../assets/fonts/alex-brush-v13-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* bonheur-royale-regular - latin */
@font-face {
  font-family: 'Bonheur Royale';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../assets/fonts/bonheur-royale-v7-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../assets/fonts/bonheur-royale-v7-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* corinthia-regular - latin */
@font-face {
  font-family: 'Corinthia';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../../../../assets/fonts/corinthia-v7-latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../../../../../assets/fonts/corinthia-v7-latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.alex-brush-font {
  font-family: Alex Brush;
}

.bonheur-royale-font {
  font-family: Bonheur Royale;
}

.corinthia-font {
  font-family: Corinthia;
}
</style>
