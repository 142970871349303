<template>
  <div ref="patient-home-view" class="container-lg">
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  components: {
  },
  setup() {

    const patientHomeView = ref(null);

    onMounted(() => {
      window.scrollTo(0, patientHomeView.value.offsetTop);
    });

    return {
      'patient-home-view': patientHomeView,
    };
  },
};
</script>
