<template>
  <div id="collapse-A" class="collapse show">
    <div ref="life-plan-smry-container" class="card-body position-relative">
      <div class="row">
        <!-- dreams -->
        <div class="col-md-7">
          <h3 class="mb-3">My Dreams</h3>
          <h4>Million Dollar Question</h4>
          <p>
            {{ lifePlanData.millionDollarQuestion || 'No response' }}
          </p>
          <h4>Where Do You Want to Retire?</h4>
          <p>
            {{ lifePlanData.retirement || 'No response' }}
          </p>
          <hr />
          <!-- plans -->
          <h3>Action Plan</h3>
          <h4>Immediate Goals</h4>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="lifePlanData.goals && lifePlanData.goals != '<p><br></p>'"
            v-html="lifePlanData.goals"
          ></div>
          <!-- eslint-enable -->
          <div v-else class="py-1">
            <span class="text-muted"> No immediate goals </span>
          </div>
          <hr />
          <div class="row row-cols-1 row-cols-sm-2">
            <div class="col">
              <h5>1 Year</h5>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="lifePlanData.year1 && lifePlanData.year1 != '<p><br></p>'"
                v-html="lifePlanData.year1"
              ></div>
              <!-- eslint-enable -->
              <div v-else class="py-1">
                <span class="text-muted"> No 1 year goals </span>
              </div>
            </div>
            <div class="col">
              <h5>3 Year</h5>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="lifePlanData.year3 && lifePlanData.year3 != '<p><br></p>'"
                v-html="lifePlanData.year3"
              ></div>
              <!-- eslint-enable -->
              <div v-else class="py-1">
                <span class="text-muted"> No 3 year goals </span>
              </div>
            </div>
            <div class="col">
              <h5>5 Year</h5>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="lifePlanData.year5 && lifePlanData.year5 != '<p><br></p>'"
                v-html="lifePlanData.year5"
              ></div>
              <!-- eslint-enable -->
              <div v-else class="py-1">
                <span class="text-muted"> No 5 year goals </span>
              </div>
            </div>
            <div class="col">
              <h5>10 Year</h5>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="
                  lifePlanData.year10 && lifePlanData.year10 != '<p><br></p>'
                "
                v-html="lifePlanData.year10"
              ></div>
              <!-- eslint-enable -->
              <div v-else class="py-1">
                <span class="text-muted"> No 10 year goals </span>
              </div>
            </div>
          </div>
        </div>

        <!-- bucket List -->
        <div class="col">
          <h3>Bucket List</h3>
          <div class="card text-white bg-gold mb-3">
            <div class="card-body">
              <h3 class="card-title">
                <i class="fas fa-trophy fa-1x"></i>
                {{ numCompleted > 0 ? 'Congratulations' : '' }}
              </h3>
              <p class="card-text">
                You have completed {{ numCompleted }} items on your bucket list
              </p>
            </div>

            <ul class="list-group list-group-flush">
              <li
                v-for="bucketItem in pagedBucketList"
                :key="bucketItem.id"
                class="list-group-item"
              >
                {{ bucketItem.text }}
              </li>
            </ul>
            <tbl-page-seln
              v-model="pgNum"
              class="mt-3"
              :total-pages="totalPages"
              @updated="refresh"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import {
  getLodashArray,
  getPage,
} from '../../.././../services/lib/clientPaging';
import TblPageSeln from '../../shared/components/TblPageSeln.vue';

export default {
  components: {
    TblPageSeln,
  },
  setup() {
    const lifeplanApi = inject('api').lifeplanApi();
    const loading = inject('$loading');
    const lifePlanSmryContainer = ref(null);

    onMounted(() => {
      refresh();
    });

    const lifePlanData = ref({
      millionDollarQuestion: null,
      retirement: null,
      goals: '',
      year1: '',
      year3: '',
      year5: '',
      year10: '',
    });

    const pagedBucketList = ref([]);
    const pgNum = ref(1); // 1 = first page
    const totalPages = ref(0);

    const refresh = async () => {
      const loader = loading.show({
        container: lifePlanSmryContainer.value,
        isFullPage: false,
      });

      const lpResponse = await lifeplanApi.getLifePlan();
      lifePlanData.value = lpResponse.data;

      const blResponse = await lifeplanApi.getBucketList();
      const bucketList = blResponse.data;

      const result = getPage(
        getLodashArray(bucketList),
        pgNum.value, // page to display
        10, // nbr of items per page
        (i) => i.completed, // filter completed
        (o) => o.text.toLowerCase(), // sorting property
        'asc' // sorting direction
      );

      pagedBucketList.value = result.data;
      totalPages.value = result.totalPages;
      calcNumCompleted(bucketList);
      loader.hide();
    };

    const numCompleted = ref(0);
    const calcNumCompleted = (bucketList) => {
      numCompleted.value = 0;
      for (let item of bucketList) {
        if (item.completed) {
          numCompleted.value += 1;
        }
      }
    };

    return {
      'life-plan-smry-container': lifePlanSmryContainer,
      refresh,
      lifePlanData,
      pagedBucketList,
      pgNum,
      totalPages,
      numCompleted,
    };
  },
};
</script>
